import { Link } from "react-router-dom";

import { logo } from "../../../assets";
import { MdOutlinePassword } from "react-icons/md";
import { HiOutlineUserCircle } from "react-icons/hi";

const CustomerMobileMenu = () => {
  return (
    <div
      className="lg:hidden inline-flex
      fixed bottom-2 left-1/2 transform
      -translate-x-1/2 z-50 mx-auto
      justify-between w-10/12 rounded-3xl
      bg-[#1d1f25] bg-opacity-60 hover:bg-opacity-100
      transition-all duration-100 ease-linear
      hover:transition-all hover:duration-100 hover:ease-linear
      "
    >
      {/* 1 */}
      <Link
        to="/Customer/Profile"
        className="inline-flex flex-col items-center 
      text-[#A79B81] hover:text-amber-300 py-3
        -px-4 flex-grow"
      >
        <HiOutlineUserCircle size={30} />
      </Link>
      {/* 2 */}
      <button
        className="relative inline-flex flex-col
        items-center py-3 px-6 flex-grow"
      >
        <Link
          to="/Public/Home"
          className="absolute bottom-4 rounded-full
          border-b-[4px] border-r-[5px] border-l-[5px]
         border-[#252839] bg-transparent"
        >
          <img
            src={logo}
            alt="Website Logo"
            width={60}
            className="rounded-full bg-cover"
          />
        </Link>
      </button>
      {/* 4 */}
      <Link
        to="/Customer/Change-Password"
        className="inline-flex flex-col items-center 
      text-[#A79B81] hover:text-amber-300 py-3
        -px-4 flex-grow"
      >
        <MdOutlinePassword size={30} />
      </Link>
    </div>
  );
};

export default CustomerMobileMenu;
