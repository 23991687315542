import CustomerPcMenu from "./CustomerPcMenu/CustomerPcMenu";
import CustomerMobileMenu from "./CustomerMobileMenu/CustomerMobileMenu";

const CustomerLayout = ({ children }) => {
  return (
    <>
      <CustomerPcMenu />
      <main>{children}</main>
      <CustomerMobileMenu />
    </>
  );
};

export default CustomerLayout;
