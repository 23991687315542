import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { isEmpty } from "lodash";
import { decodeJWT } from "../../utils";

import PublicLayout from "../../layouts/PublicLayout/PublicLayout";

import HomePublic from "../../components/Public/HomePublic/HomePublic";
import CustomerLastTransactions from "../../components/Customer/CustomerLastTransactions/CustomerLastTransactions";
import CustomerLiveTransactions from "../../components/Customer/CustomerLiveTransactions/CustomerLiveTransactions";

import AdminLiveTransactions from "../../components/Admin/AdminLiveTransactions/AdminLiveTransactions";

import TransactionsCustomerContext from "../../context/CustomerContext/TransactionsCustomerContext/TransactionsCustomerContext";
import TransactionAdminContext from "../../context/AdminContext/TransactionAdminContext/TransactionAdminContext";
import AdminLastTransactions from "../../components/Admin/AdminLastTransactions/AdminLastTransactions";

const PublicContainer = () => {
  const token = localStorage.getItem("token");

  const isLogin = !isEmpty(token);

  const roleName = decodeJWT(token)?.usertype;

  if (!isLogin) return <Navigate to="/" replace />;

  return (
    <>
      <PublicLayout>
        <Routes>
          {/* Home Component */}
          <Route path="/Home" element={<HomePublic />} />
          {roleName === "CUSTOMER" && (
            <>
              {/* Last Transactions Component */}
              <Route
                path="/Last-Transactions"
                element={
                  <TransactionsCustomerContext>
                    <CustomerLastTransactions />
                  </TransactionsCustomerContext>
                }
              />
              {/* Live Transactions Component */}
              <Route
                path="/Live-Transactions"
                element={
                  <TransactionsCustomerContext>
                    <CustomerLiveTransactions />
                  </TransactionsCustomerContext>
                }
              />
            </>
          )}
          {roleName === "ADMIN" && (
            <>
              {/* Last Transaction Component */}
              <Route
                path="/Last-Transactions"
                element={
                  <TransactionAdminContext>
                    <AdminLastTransactions />
                  </TransactionAdminContext>
                }
              />
              {/* Live Transactions Component */}
              <Route
                path="/Live-Transactions"
                element={
                  <TransactionAdminContext>
                    <AdminLiveTransactions />
                  </TransactionAdminContext>
                }
              />
            </>
          )}
        </Routes>
      </PublicLayout>
    </>
  );
};

export default PublicContainer;
