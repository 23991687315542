import PricePublicContext from "../../../context/PublicContext/PricePublicContext/PricePublicContext";
import MobilePriceView from "../MobilePriceView/MobilePriceView";
import PcPriceView from "../PcPriceView/PcPriceView";

const HomePublic = () => {
  return (
    <>
      <div className="h-full bg-[#252839]">
        <div className="lg:flex hidden">
          <span
            className="h-full xl:w-[85%] w-[82%] absolute xl:left-0 left-4
           mt[3em] mx-auto rounded-lg overflow-y-auto"
          >
            <PricePublicContext>
              <PcPriceView />
            </PricePublicContext>
          </span>
        </div>
        <div className="lg:hidden flex">
          <PricePublicContext>
            <MobilePriceView />
          </PricePublicContext>
        </div>
      </div>
    </>
  );
};

export default HomePublic;
