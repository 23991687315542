import PublicMobileMenu from "./PublicMobileMenu/PublicMobileMenu";
import PublicPcMenu from "./PublicPcMenu/PublicPcMenu";

const PublicLayout = ({ children }) => {
  return (
    <>
      <PublicPcMenu />
      <main>{children}</main>
      <PublicMobileMenu />
    </>
  );
};

export default PublicLayout;
