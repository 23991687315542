import AdminDashboardNews from "./AdminDashboardNews/AdminDashboardNews";
import AdminDashboardProducts from "./AdminDashboardProducts/AdminDashboardProducts";
import AdminDashboardProfile from "./AdminDashboardProfile/AdminDashboardProfile";
import AdminDashboardRequests from "./AdminDashboardRequests/AdminDashboardRequests";
import AdminDashboardUsers from "./AdminDashboardUsers/AdminDashboardUsers";

const AdminDashboard = () => {
  return (
    <>
      <div className="absolute 2xl:w-[85%] xl:w-[75%] lg:w-[70%] w-full">
        <div className="flex w-full justify-center items-center rounded-xl bg-[#252839] relative">
          {/* Profile Admin Page */}
          <AdminDashboardProfile />
          {/* Users Admin Page */}
          <AdminDashboardUsers />
          {/* Products Admin Page */}
          <AdminDashboardProducts />
          {/* Requests Admin Page */}
          <AdminDashboardRequests />
          {/* News Admin Page */}
          <AdminDashboardNews />
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
