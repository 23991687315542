import { useContext, useEffect } from "react";
import { isEmpty } from "lodash";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import PersianNumber from "../../../../utils/PersianNumber/PersianNumber";
import { areYouSureMessage } from "../../../../utils/toastMessage/toastMessage";

import { HiOutlinePlusSm, HiOutlineX } from "react-icons/hi";
import { IoIosPeople } from "react-icons/io";
import { MdClose, MdCheck } from "react-icons/md";

import SiteTitle from "../../../../helpers/SiteTitle/SiteTitle";
import { usersAdminContext } from "../../../../context/AdminContext/UsersAdminContext/UsersAdminContext";
import { genderType } from "../../../../utils/index";
import ValidationErrorMessage from "../../../../helpers/ValidationErrorMessage/ValidationErrorMessage";
import {
  createUserValidation,
  editUserToleranceAdminValidation,
  editUserValidation,
} from "../../../../utils/validation/validation";

const AdminDashboardUsers = () => {
  const {
    getUsers,
    addUser,
    setAddUser,
    userId,
    handleCreateUserAdmin,
    handleGetUsersAdmin,
    handleOpenEditModal,
    handleCloseEditModal,
    handleEditUserAdmin,
    handleChangeActiveUser,
    handleDeleteUser,
    // * Tolerance Part
    getProducts,
    userID,
    handleGetProducts,
    handleGetUserTolerance,
    handleOpenEditModalUserTolerance,
    handleCloseEditModalUserTolerance,
    handleEditUserToleranceAdmin,
    // ?
    isOpenInfo,
    productName,
    setProductName,
    bTolerance,
    handleBuyToleranceChange,
    sTolerance,
    handleSellToleranceChange,
  } = useContext(usersAdminContext);

  useEffect(() => {
    handleGetUsersAdmin();
  }, []);

  useEffect(() => {
    handleGetProducts();
  }, []);

  // Validation Create Users
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createUserValidation),
  });

  // Validation Edit Users
  const {
    register: registerEditUser,
    handleSubmit: handleSubmitEditUser,
    setValue: setValueEditUser,
    reset: resetEditUser,
    watch: watchEditUser,
    formState: { errors: errorsEditUser },
  } = useForm({
    resolver: yupResolver(editUserValidation),
  });

  // Validation User Tolerance
  const {
    register: registerUT,
    handleSubmit: handleSubmitUT,
    formState: { errors: errorsUT },
  } = useForm({
    resolver: yupResolver(editUserToleranceAdminValidation),
  });

  return (
    <>
      <SiteTitle title="لیست کاربران" />
      <div className="mr-auto 2xl:w-[85%] xl:w-[75%] lg:w-[70%] w-full h-full mb-16">
        <div className="flex h-full justify-center items-center rounded-xl bg-[#252839]">
          <div
            className="rounded-xl flex flex-col h-full w-full relative
              animate__animated animate__fadeIn animate__fast"
          >
            {/* Table */}
            <div className="w-full flex-col mt-10">
              {/* Add User Button */}
              <span className="bg-green-500 hover:bg-green-600 rounded-full text-white float-left ml-2">
                <button
                  onClick={() => {
                    reset();
                    setAddUser(true);
                  }}
                  className="flex items-center px-2 lg:py-2 py-1 font-bold lg:text-sm text-xs tracking-wide"
                >
                  اضافه کردن کاربر
                  <HiOutlinePlusSm size={20} />
                </button>
              </span>
              {isEmpty(getUsers) ? (
                <>
                  <span
                    className="mr-auto w-full py-3 mt-10
                  text-slate-200 text-xl flex flex-col
                   justify-center items-center absolute top-[50%]"
                  >
                    <span>
                      <IoIosPeople size={60} />
                    </span>
                    <p>کاربری وجود ندارد</p>
                  </span>
                </>
              ) : (
                <>
                  <table className="w-full h-full">
                    <thead className="text-sm font-medium text-slate-200 border-b-[1px] border-[#A79B81]">
                      <tr>
                        <th className="py-3">
                          <div>نام کاربر</div>
                        </th>
                        <th className="py-3">
                          <div>شماره موبایل</div>
                        </th>
                        <th className="py-3">
                          <div>کد ملی</div>
                        </th>
                        <th className="py-3">
                          <div>وظیفه</div>
                        </th>
                        <th className="py-3">
                          <div>جنسیت</div>
                        </th>
                        <th className="py-3">
                          <div>وضعیت</div>
                        </th>
                        <th className="py-3">
                          <div>عملیات</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-lg divide-y divide-gray-700 w-full">
                      {getUsers.map((_item) => (
                        <>
                          <tr
                            key={_item.id}
                            className="hover:bg-gray-900 w-full h-16 relative"
                          >
                            {/* User Name */}
                            <td className="py-4">
                              <div className="flex justify-center text-center lg:text-sm text-xs font-medium text-slate-200">
                                <p>
                                  {_item.firstname} {_item.lastname}
                                </p>
                              </div>
                            </td>
                            {/* Phone Number */}
                            <td className="py-4">
                              <div className="flex justify-center text-gray-400 lg:text-sm text-xs">
                                <p className="tracking-wider">
                                  <PersianNumber value={_item.phonenumber} />
                                </p>
                              </div>
                            </td>
                            {/* National Number */}
                            <td className="py-4">
                              <div className="flex justify-center text-gray-400 lg:text-sm text-xs">
                                <p className="tracking-wider">
                                  {/* <PersianNumber value={_item.nationalid} /> */}
                                  {_item.nationalid}
                                </p>
                              </div>
                            </td>
                            {/* Position */}
                            <td className="py-4">
                              <div
                                className="flex justify-center text-center
                      lg:text-sm text-xs font-medium text-slate-200"
                              >
                                <p
                                  className={`${
                                    _item.user_type === "CUSTOMER"
                                      ? "bg-blue-400"
                                      : "bg-yellow-400"
                                  }
                         px-2.5 rounded-full text-black`}
                                >
                                  {_item.user_type === "CUSTOMER"
                                    ? "کاربر"
                                    : "ادمین"}
                                </p>
                              </div>
                            </td>
                            {/* Gender */}
                            <td className="py-4">
                              <div
                                className="flex justify-center text-center
                      lg:text-sm text-xs font-medium text-slate-200"
                              >
                                <p className="px-2.5 rounded-full text-slate-200">
                                  {genderType(_item.gender)}
                                </p>
                              </div>
                            </td>
                            {/* Active */}
                            <td className="py-4">
                              <div
                                className="flex justify-center text-center
                                lg:text-sm text-xs font-medium text-slate-200"
                              >
                                <p>
                                  {_item.isAllowed ? (
                                    <MdCheck size={25} fill="#22c55e" />
                                  ) : (
                                    <MdClose size={25} fill="#dc2626" />
                                  )}
                                </p>
                              </div>
                            </td>
                            {/* Buttons */}
                            <td className="py-4">
                              <div
                                className="flex lg:flex-row-reverse flex-col 
                                justify-center items-center
                              text-gray-950 lg:text-sm text-xs font-bold
                                lg:space-x-2 lg:space-y-0 space-y-2 space-x-0"
                              >
                                {/* Delete User */}
                                <span className="">
                                  <button
                                    onClick={() =>
                                      areYouSureMessage(
                                        () => handleDeleteUser(_item.id),
                                        `آیا از حذف ${_item.firstname} ${_item.lastname} مطمئنید؟`
                                      )
                                    }
                                    className="bg-red-600 hover:bg-red-700 tracking-wide
                        text-white lg:px-2.5 px-1.5 lg:py-1 py-0.5 rounded-full"
                                  >
                                    حذف
                                  </button>
                                </span>
                                {/* Active User */}
                                <span>
                                  {_item.isAllowed ? (
                                    <button
                                      onClick={() =>
                                        handleChangeActiveUser(_item.id, false)
                                      }
                                      className="bg-green-500 hover:bg-green-600 
                                      tracking-wide text-white lg:px-2.5 px-1.5 
                                      lg:py-1 py-0.5 rounded-full"
                                    >
                                      فعال
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() =>
                                        handleChangeActiveUser(_item.id, true)
                                      }
                                      className="bg-yellow-500 hover:bg-yellow-600
                                    tracking-wide text-white lg:px-2.5 px-1.5 
                                    lg:py-1 py-0.5 rounded-full"
                                    >
                                      غیرفعال
                                    </button>
                                  )}
                                </span>
                                {/* Edit */}
                                <span>
                                  <button
                                    onClick={() =>
                                      handleOpenEditModal(
                                        _item,
                                        setValueEditUser
                                      )
                                    }
                                    className="bg-yellow-600 hover:bg-yellow-700 tracking-wide
                                  text-white lg:px-2.5 px-1.5 lg:py-1 py-0.5 rounded-full"
                                  >
                                    ویرایش
                                  </button>
                                </span>
                                {/* Tolerance User */}
                                <span>
                                  <button
                                    onClick={() =>
                                      handleOpenEditModalUserTolerance(_item)
                                    }
                                    className="bg-blue-600 hover:bg-blue-700 tracking-wide
                                  text-white lg:px-2.5 px-1.5 lg:py-1 py-0.5 rounded-full"
                                  >
                                    سود
                                  </button>
                                </span>
                              </div>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </div>
            {/* Create Modal */}
            {addUser && (
              <>
                <div
                  className={`${
                    addUser
                      ? window.scrollTo({ top: 0, behavior: "smooth" })
                      : null
                  }
  flex absolute bg-black bg-opacity-40
  inset-0 items-center w-full z-50 mr-auto h-screen
  animate__animated animate__fadeIn animate__fast
`}
                >
                  <div
                    className="flex flex-col absolute top-20 left-0 right-0 
                  w-11/12 sm:w-5/6 lg:w-12/12 max-w-2xl mx-auto rounded-lg 
                  border-2 border-[#252839]"
                  >
                    <div className="flex flex-row justify-between p-5 bg-[#252839]">
                      {/* Title - Close Icon */}
                      <span className="flex justify-between w-full">
                        {/* Title */}
                        <span>
                          <p className="font-semibold text-gray-400">
                            اضافه کردن کاربر
                          </p>
                        </span>
                        {/* Close Icon */}
                        <span>
                          <HiOutlineX
                            size={25}
                            onClick={() => setAddUser(false)}
                            className="text-gray-400 cursor-pointer"
                          />
                        </span>
                      </span>
                    </div>
                    <span className="border-b-2 border-[#1A1D27]" />
                    {/* Inputs */}
                    <div className="w-full px-6 py-5 bg-[#252839]">
                      <form onSubmit={handleSubmit(handleCreateUserAdmin)}>
                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-3 w-full">
                          {/* Part 1 */}
                          <span>
                            {/* First Name */}
                            <span>
                              <label
                                htmlFor="firstname"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                نام کاربر
                              </label>
                              <input
                                type="text"
                                name="firstname"
                                placeholder="نام کاربر"
                                autoComplete="off"
                                {...register("firstname")}
                                className="p-2 mb-2 bg-transparent border-2 border-slate-600 w-full
                            rounded-md focus:right-0 focus:outline-none tracking-wide text-slate-400
                            focus:border-yellow-400 focus:text-yellow-400"
                              />
                              <span>
                                <ValidationErrorMessage
                                  message={errors.firstname?.message}
                                />
                              </span>
                            </span>
                            {/* Phone Number */}
                            <span>
                              <label
                                htmlFor="phonenumber"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                شماره موبایل کاربر
                              </label>
                              <input
                                type="text"
                                name="phonenumber"
                                placeholder="شماره موبایل کاربر"
                                autoComplete="off"
                                {...register("phonenumber")}
                                className="p-2 mb-2 bg-transparent border-2 border-slate-600 w-full
                            rounded-md focus:right-0 focus:outline-none tracking-wide text-slate-400
                            focus:border-yellow-400 focus:text-yellow-400"
                              />
                              <span>
                                <ValidationErrorMessage
                                  message={errors.phonenumber?.message}
                                />
                              </span>
                            </span>
                            {/* Gender */}
                            <span>
                              <label
                                htmlFor="gender"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                جنسیت کاربر
                              </label>
                              <select
                                name="gender"
                                {...register("gender")}
                                className="p-2 mb-2 bg-transparent border-2 border-slate-600 w-full
                            rounded-md focus:right-0 focus:outline-none tracking-wide text-slate-400
                            focus:border-yellow-400 focus:text-yellow-400"
                              >
                                <option
                                  className="text-gray-200 p-1 bg-[#252839]"
                                  selected
                                  hidden
                                  value=""
                                >
                                  جنسیت
                                </option>
                                <option
                                  className="text-gray-200 p-1 bg-[#252839]"
                                  selected={watch("gender") == 0}
                                  value={0}
                                >
                                  نامشخص
                                </option>
                                <option
                                  className="text-gray-200 p-1 bg-[#252839]"
                                  selected={watch("gender") == 1}
                                  value={1}
                                >
                                  مرد
                                </option>
                                <option
                                  className="text-gray-200 p-1 bg-[#252839]"
                                  selected={watch("gender") == 2}
                                  value={2}
                                >
                                  زن
                                </option>
                              </select>
                              <span>
                                <ValidationErrorMessage
                                  message={errors.gender?.message}
                                />
                              </span>
                            </span>
                            {/* Password */}
                            <span>
                              <label
                                htmlFor="password"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                رمز عبور کاربر
                              </label>
                              <input
                                type="text"
                                name="password"
                                placeholder="رمز عبور کاربر"
                                autoComplete="off"
                                {...register("password")}
                                className="p-2 mb-2 bg-transparent border-2 border-slate-600 w-full
                            rounded-md focus:right-0 focus:outline-none tracking-wide text-slate-400
                            focus:border-yellow-400 focus:text-yellow-400"
                              />
                              <span>
                                <ValidationErrorMessage
                                  message={errors.password?.message}
                                />
                              </span>
                            </span>
                          </span>
                          {/* Part 2 */}
                          <span>
                            {/* Last Name */}
                            <span>
                              <label
                                htmlFor="lastname"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                نام خانوادگی کاربر
                              </label>
                              <input
                                type="text"
                                name="lastname"
                                placeholder="نام خانوادگی کاربر"
                                autoComplete="off"
                                {...register("lastname")}
                                className="p-2 mb-2 bg-transparent border-2 border-slate-600 w-full
                            rounded-md focus:right-0 focus:outline-none tracking-wide text-slate-400
                            focus:border-yellow-400 focus:text-yellow-400"
                              />
                              <span>
                                <ValidationErrorMessage
                                  message={errors.lastname?.message}
                                />
                              </span>
                            </span>
                            {/* National Number */}
                            <span>
                              <label
                                htmlFor="nationalid"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                کد ملی کاربر
                              </label>
                              <input
                                type="text"
                                name="nationalid"
                                placeholder="کد ملی کاربر"
                                autoComplete="off"
                                {...register("nationalid")}
                                className="p-2 mb-2 bg-transparent border-2 border-slate-600 w-full
                            rounded-md focus:right-0 focus:outline-none tracking-wide text-slate-400
                            focus:border-yellow-400 focus:text-yellow-400"
                              />
                              <span>
                                <ValidationErrorMessage
                                  message={errors.nationalid?.message}
                                />
                              </span>
                            </span>
                            {/* User Type */}
                            <span>
                              <label
                                htmlFor="userType"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                سمت
                              </label>
                              <select
                                name="userType"
                                {...register("user_type")}
                                className="p-2 mb-2 bg-transparent border-2 border-slate-600 w-full
                            rounded-md focus:right-0 focus:outline-none tracking-wide text-slate-400
                            focus:border-yellow-400 focus:text-yellow-400"
                              >
                                <option
                                  className="text-gray-200 p-1 bg-[#252839]"
                                  selected
                                  hidden
                                  value=""
                                >
                                  سمت کاربر
                                </option>
                                <option
                                  className="text-gray-200 p-1 bg-[#252839]"
                                  selected={watch("user_type") == "ADMIN"}
                                  value={"ADMIN"}
                                >
                                  ادمین
                                </option>
                                <option
                                  className="text-gray-200 p-1 bg-[#252839]"
                                  selected={watch("user_type") == "CUSTOMER"}
                                  value={"CUSTOMER"}
                                >
                                  کاربر
                                </option>
                              </select>
                              <span>
                                <ValidationErrorMessage
                                  message={errors.user_type?.message}
                                />
                              </span>
                            </span>
                            {/* Confirm Password */}
                            <span>
                              <label
                                htmlFor="confirmpassword"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                تکرار رمز عبور کاربر
                              </label>
                              <input
                                type="text"
                                name="confirmpassword"
                                placeholder="تکرار رمز عبور کاربر"
                                autoComplete="off"
                                {...register("confirmpassword")}
                                className="p-2 mb-2 bg-transparent border-2 border-slate-600 w-full
                            rounded-md focus:right-0 focus:outline-none tracking-wide text-slate-400
                            focus:border-yellow-400 focus:text-yellow-400"
                              />
                              <span>
                                <ValidationErrorMessage
                                  message={errors.confirmpassword?.message}
                                />
                              </span>
                            </span>
                          </span>
                        </div>
                        {/* Button */}
                        <div className="flex items-center justify-end p-5 w-full bg-[#252839]">
                          <button
                            type="submit"
                            className="px-4 py-1.5 text-white text-sm font-medium bg-green-500 hover:bg-green-600 rounded"
                          >
                            اضافه کردن
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </>
            )}
            {/* Edit Modal */}
            {userId && (
              <div
                className={`${
                  userId
                    ? window.scrollTo({ top: 0, behavior: "smooth" })
                    : null
                }
              flex absolute bg-black bg-opacity-40
            inset-0 items-center w-full z-50 mr-auto h-screen
            animate__animated animate__fadeIn animate__fast
              `}
              >
                <div
                  className="flex flex-col absolute top-20 left-0 right-0 
                  w-11/12 sm:w-5/6 lg:w-12/12 max-w-2xl mx-auto rounded-lg 
                  border-2 border-[#252839]"
                >
                  <div className="flex flex-row justify-between p-5 bg-[#252839]">
                    {/* Title - Close Icon */}
                    <span className="flex justify-between w-full">
                      {/* Title */}
                      <span>
                        <p className="font-semibold text-gray-400">
                          ویرایش کردن کاربر
                        </p>
                      </span>
                      {/* Close Icon */}
                      <span>
                        <HiOutlineX
                          size={25}
                          onClick={() => {
                            handleCloseEditModal();
                            resetEditUser();
                          }}
                          className="text-gray-400 cursor-pointer"
                        />
                      </span>
                    </span>
                  </div>
                  <span className="border-b-2 border-[#1A1D27]" />
                  {/* Inputs */}
                  <div className="w-full px-6 py-5 bg-[#252839]">
                    <form onSubmit={handleSubmitEditUser(handleEditUserAdmin)}>
                      <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-3 w-full">
                        {/* Part 1 */}
                        <span>
                          {/* First Name */}
                          <span>
                            <label
                              htmlFor="firstname"
                              className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                            >
                              نام کاربر
                            </label>
                            <input
                              type="text"
                              name="firstname"
                              placeholder="نام کاربر"
                              autoComplete="off"
                              {...registerEditUser("firstname")}
                              className="p-2 mb-2 bg-transparent border-2 border-slate-600 w-full
                            rounded-md focus:right-0 focus:outline-none tracking-wide text-slate-400
                            focus:border-yellow-400 focus:text-yellow-400"
                            />
                            <span>
                              <ValidationErrorMessage
                                message={errorsEditUser.firstname?.message}
                              />
                            </span>
                          </span>
                          {/* Phone Number */}
                          <span>
                            <label
                              htmlFor="phonenumber"
                              className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                            >
                              شماره موبایل کاربر
                            </label>
                            <input
                              type="text"
                              name="phonenumber"
                              placeholder="شماره موبایل کاربر"
                              autoComplete="off"
                              {...registerEditUser("phonenumber")}
                              className="p-2 mb-2 bg-transparent border-2 border-slate-600 w-full
                            rounded-md focus:right-0 focus:outline-none tracking-wide text-slate-400
                            focus:border-yellow-400 focus:text-yellow-400"
                            />
                            <span>
                              <ValidationErrorMessage
                                message={errorsEditUser.phonenumber?.message}
                              />
                            </span>
                          </span>
                          {/* Gender */}
                          <span>
                            <label
                              htmlFor="gender"
                              className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                            >
                              جنسیت کاربر
                            </label>
                            <select
                              name="gender"
                              {...registerEditUser("gender")}
                              className="p-2 mb-2 bg-transparent border-2 border-slate-600 w-full
                            rounded-md focus:right-0 focus:outline-none tracking-wide text-slate-400
                            focus:border-yellow-400 focus:text-yellow-400"
                            >
                              <option
                                className="text-gray-200 p-1 bg-[#252839]"
                                selected
                                hidden
                                value=""
                              >
                                جنسیت
                              </option>
                              <option
                                className="text-gray-200 p-1 bg-[#252839]"
                                selected={watchEditUser("gender") == 0}
                                value={0}
                              >
                                نامشخص
                              </option>
                              <option
                                className="text-gray-200 p-1 bg-[#252839]"
                                selected={watchEditUser("gender") == 1}
                                value={1}
                              >
                                مرد
                              </option>
                              <option
                                className="text-gray-200 p-1 bg-[#252839]"
                                selected={watchEditUser("gender") == 2}
                                value={2}
                              >
                                زن
                              </option>
                            </select>
                            <span>
                              <ValidationErrorMessage
                                message={errorsEditUser.gender?.message}
                              />
                            </span>
                          </span>
                          {/* Active Type */}
                          <span>
                            <label
                              htmlFor="isAllowed"
                              className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                            >
                              وضعیت کاربر
                            </label>
                            <select
                              name="isAllowed"
                              {...registerEditUser("isAllowed")}
                              className="p-2 mb-2 bg-transparent border-2 border-slate-600 w-full
                            rounded-md focus:right-0 focus:outline-none tracking-wide text-slate-400
                            focus:border-yellow-400 focus:text-yellow-400"
                            >
                              <option
                                className="text-gray-200 p-1 bg-[#252839]"
                                selected
                                hidden
                                value=""
                              >
                                وضعیت کاربر
                              </option>
                              <option
                                className="text-gray-200 p-1 bg-[#252839]"
                                selected={watch("isAllowed") == false}
                                value={false}
                              >
                                غیر فعال
                              </option>
                              <option
                                className="text-gray-200 p-1 bg-[#252839]"
                                selected={watch("isAllowed") == true}
                                value={true}
                              >
                                فعال
                              </option>
                            </select>
                            <span>
                              <ValidationErrorMessage
                                message={errorsEditUser.isAllowed?.message}
                              />
                            </span>
                          </span>
                        </span>
                        {/* Part 2 */}
                        <span>
                          {/* Last Name */}
                          <span>
                            <label
                              htmlFor="lastname"
                              className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                            >
                              نام خانوادگی کاربر
                            </label>
                            <input
                              type="text"
                              name="lastname"
                              placeholder="نام خانوادگی کاربر"
                              autoComplete="off"
                              {...registerEditUser("lastname")}
                              className="p-2 mb-2 bg-transparent border-2 border-slate-600 w-full
                            rounded-md focus:right-0 focus:outline-none tracking-wide text-slate-400
                            focus:border-yellow-400 focus:text-yellow-400"
                            />
                            <span>
                              <ValidationErrorMessage
                                message={errorsEditUser.lastname?.message}
                              />
                            </span>
                          </span>
                          {/* National Number */}
                          <span>
                            <label
                              htmlFor="nationalid"
                              className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                            >
                              کد ملی کاربر
                            </label>
                            <input
                              type="text"
                              name="nationalid"
                              placeholder="کد ملی کاربر"
                              autoComplete="off"
                              {...registerEditUser("nationalid")}
                              className="p-2 mb-2 bg-transparent border-2 border-slate-600 w-full
                            rounded-md focus:right-0 focus:outline-none tracking-wide text-slate-400
                            focus:border-yellow-400 focus:text-yellow-400"
                            />
                            <span>
                              <ValidationErrorMessage
                                message={errorsEditUser.nationalid?.message}
                              />
                            </span>
                          </span>
                          {/* User Type */}
                          <span>
                            <label
                              htmlFor="userType"
                              className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                            >
                              وظیفه
                            </label>
                            <select
                              name="userType"
                              {...registerEditUser("user_type")}
                              className="p-2 mb-2 bg-transparent border-2 border-slate-600 w-full
                            rounded-md focus:right-0 focus:outline-none tracking-wide text-slate-400
                            focus:border-yellow-400 focus:text-yellow-400"
                            >
                              <option
                                className="text-gray-200 p-1 bg-[#252839]"
                                selected
                                hidden
                                value=""
                              >
                                وظیفه کاربر
                              </option>
                              <option
                                className="text-gray-200 p-1 bg-[#252839]"
                                selected={watch("user_type") == "ADMIN"}
                                value={"ADMIN"}
                              >
                                ادمین
                              </option>
                              <option
                                className="text-gray-200 p-1 bg-[#252839]"
                                selected={watch("user_type") == "CUSTOMER"}
                                value={"CUSTOMER"}
                              >
                                کاربر
                              </option>
                            </select>
                            <span>
                              <ValidationErrorMessage
                                message={errorsEditUser.user_type?.message}
                              />
                            </span>
                          </span>
                        </span>
                      </div>
                      {/* Button */}
                      <div className="flex items-center justify-end p-5 w-full bg-[#252839]">
                        <button
                          type="submit"
                          className="px-4 py-1.5 text-white text-sm font-medium bg-green-500 hover:bg-green-600 rounded"
                        >
                          ویرایش کردن
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
            {/* Tolerance Modal */}
            {userID && (
              <div
                className={`${
                  userID
                    ? window.scrollTo({ top: 0, behavior: "smooth" })
                    : null
                }
                flex absolute bg-black bg-opacity-40
                inset-0 items-center w-full z-50 mr-auto h-screen
                animate__animated animate__fadeIn animate__fast
            `}
              >
                <div
                  className="flex flex-col absolute top-20 left-0 right-0 
                  w-11/12 sm:w-5/6 lg:w-12/12 max-w-2xl mx-auto rounded-lg 
                  border-2 border-[#252839]"
                >
                  {/* Title - Close Icon */}
                  <div className="flex flex-row justify-between p-5 bg-[#252839]">
                    <span className="flex justify-between w-full">
                      {/* Title */}
                      <span>
                        <p className="font-semibold text-gray-400">سود کاربر</p>
                      </span>
                      {/* Close Icon */}
                      <span>
                        <HiOutlineX
                          size={25}
                          onClick={handleCloseEditModalUserTolerance}
                          className="text-gray-400 cursor-pointer"
                        />
                      </span>
                    </span>
                  </div>
                  <span className="border-b-2 border-[#1A1D27]" />
                  <div className="w-full px-6 py-5 bg-[#252839]">
                    {/* First Form */}
                    <form onSubmit={handleGetUserTolerance}>
                      {/* Product Name */}
                      <span className="flex flex-col w-full overflow-y-scroll">
                        <label
                          htmlFor="tag"
                          className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                        >
                          نوع محصول
                        </label>
                        <select
                          name="tag"
                          value={productName}
                          onChange={(e) => setProductName(e.target.value, e)}
                          className="p-2 mb-2 bg-transparent border-2
                          border-slate-600 w-full rounded-md focus:right-0
                          focus:outline-none tracking-wide text-slate-400
                          focus:border-2 focus:border-slate-400"
                        >
                          <option
                            className="text-gray-200 p-1 bg-[#252839]"
                            selected
                            hidden
                            value=""
                          >
                            نوع محصول
                          </option>
                          {getProducts.map((_item) => (
                            <>
                              <option
                                key={_item.id}
                                className="text-gray-200 p-1 bg-[#252839]"
                                selected={productName == _item.id}
                                value={_item.id}
                              >
                                {_item.name}
                              </option>
                            </>
                          ))}
                        </select>
                      </span>
                      {/* Button */}
                      <div className="flex items-center justify-end p-5 w-full bg-[#252839]">
                        <button
                          type="submit"
                          className="px-4 py-1.5 text-white text-sm font-medium bg-green-500 hover:bg-green-600 rounded focus:outline-none"
                        >
                          مشاهده
                        </button>
                      </div>
                    </form>
                    {/* Second Form */}
                    {isOpenInfo && (
                      <>
                        <form
                          onSubmit={(event) =>
                            handleEditUserToleranceAdmin(event, userID)
                          }
                        >
                          <span className="font-medium flex lg:flex-row flex-col">
                            {/* Buy Tolerance */}
                            <span className="w-full mx-1">
                              <label
                                htmlFor="buyTolerance"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                سود فروش
                              </label>
                              <input
                                type="text"
                                name="buyTolerance"
                                placeholder="سود فروش"
                                autoComplete="off"
                                value={bTolerance === -1 ? 0 : bTolerance}
                                onChange={handleBuyToleranceChange}
                                className="p-2 mb-2 bg-transparent border-2
                              border-slate-600 w-full rounded-md
                              focus:right-0 focus:outline-none tracking-wide
                             text-slate-400 focus:border-yellow-400
                              focus:text-yellow-400"
                              />
                            </span>
                            {/* Sell Tolerance */}
                            <span className="w-full mx-1">
                              <label
                                htmlFor="sellTolerance"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                سود خرید
                              </label>
                              <input
                                type="text"
                                name="sellTolerance"
                                placeholder="سود خرید"
                                autoComplete="off"
                                value={sTolerance === -1 ? 0 : sTolerance}
                                onChange={handleSellToleranceChange}
                                className="p-2 mb-2 bg-transparent border-2
                          border-slate-600 w-full rounded-md focus:right-0
                            focus:outline-none tracking-wide text-slate-400
                            focus:border-2 focus:border-slate-400"
                              />
                            </span>
                          </span>
                          {/* Button */}
                          <div className="flex items-center justify-end p-5 w-full bg-[#252839]">
                            <button
                              type="submit"
                              className="px-4 py-1.5 text-white text-sm font-medium bg-green-500 hover:bg-green-600 rounded focus:outline-none"
                            >
                              ذخیره
                            </button>
                          </div>
                        </form>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboardUsers;
