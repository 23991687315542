import { toastErrorMessage } from "../../../utils/toastMessage/toastMessage";
import { getUserInfoServices } from "../../../services/userServices";

export const setUserInfoAction = (userInfo) => {
  return async (dispatch) => {
    if (userInfo) {
      await dispatch({ type: "SET_USER_INFO", payload: userInfo });
    } else {
      try {
        const { status, data } = await getUserInfoServices();
        if (status === 200) {
          await dispatch({
            type: "SET_USER_INFO",
            payload: data,
          });
        }
      } catch ({ response }) {
        // Err user info
        if (response && response.status)
          toastErrorMessage(response.data.message);
      }
    }
  };
};

export const clearUserInfoAction = () => {
  return async (dispatch) => {
    await dispatch({ type: "CLEAR_USER_INFO" });
  };
};
