import React from "react";

import { Routes, Route, Navigate } from "react-router-dom";
import { isEmpty } from "lodash";

import AdminLayout from "../../layouts/AdminLayout/AdminLayout";
import { decodeJWT } from "../../utils";

import AdminDashboard from "../../components/Admin/AdminDashboard/AdminDashboard";
import AdminDashboardProfile from "../../components/Admin/AdminDashboard/AdminDashboardProfile/AdminDashboardProfile";
import AdminDashboardUsers from "../../components/Admin/AdminDashboard/AdminDashboardUsers/AdminDashboardUsers";
import AdminDashboardProducts from "../../components/Admin/AdminDashboard/AdminDashboardProducts/AdminDashboardProducts";
import AdminDashboardRequests from "../../components/Admin/AdminDashboard/AdminDashboardRequests/AdminDashboardRequests";
import AdminDashboardNews from "../../components/Admin/AdminDashboard/AdminDashboardNews/AdminDashboardNews";

import UsersAdminContext from "../../context/AdminContext/UsersAdminContext/UsersAdminContext";
import ProductsAdminContext from "../../context/AdminContext/ProductsAdminContext/ProductsAdminContext";
import RequestsAdminContext from "../../context/AdminContext/RequestsAdminContext/RequestsAdminContext";
import ChangePasswordAdminContext from "../../context/AdminContext/ChangePasswordAdminContext/ChangePasswordAdminContext";
import NewsAdminContext from "../../context/AdminContext/NewsAdminContext/NewsAdminContext";

const AdminContainer = () => {
  const token = localStorage.getItem("token");

  const isLogin = !isEmpty(token);

  const roleName = decodeJWT(token)?.usertype;

  if (!isLogin || roleName !== "ADMIN") return <Navigate to="/" replace />;

  return (
    <>
      <AdminLayout>
        <Routes>
          {/* Dashboard Admin Component */}
          <Route path="/Dashboard" element={<AdminDashboard />} />
          {/* Profile Admin Component */}
          <Route
            path="/Dashboard/Profile"
            element={
              <ChangePasswordAdminContext>
                <AdminDashboardProfile />
              </ChangePasswordAdminContext>
            }
          />
          {/* Users Admin Component */}
          <Route
            path="/Dashboard/Users"
            element={
              <UsersAdminContext>
                <AdminDashboardUsers />
              </UsersAdminContext>
            }
          />
          {/* Products Admin Component */}
          <Route
            path="/Dashboard/Products"
            element={
              <ProductsAdminContext>
                <AdminDashboardProducts />
              </ProductsAdminContext>
            }
          />
          {/* Requests Admin Component */}
          <Route
            path="/Dashboard/Requests"
            element={
              <RequestsAdminContext>
                <AdminDashboardRequests />
              </RequestsAdminContext>
            }
          />
          {/* News Admin Component */}
          <Route
            path="/Dashboard/News"
            element={
              <NewsAdminContext>
                <AdminDashboardNews />
              </NewsAdminContext>
            }
          />
        </Routes>
      </AdminLayout>
    </>
  );
};

export default AdminContainer;
