import { combineReducers } from "redux";

import { isLoaderReducer } from "./isLoaderReducer/isLoaderReducer";
import { userReducer } from "./userReducer/userReducer";
import { userInfoReducer } from "./userInfoReducer/userInfoReducer";

export const reducers = combineReducers({
  isLoader: isLoaderReducer,
  user: userReducer,
  userInfo: userInfoReducer
});