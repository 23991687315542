import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { MdOutlineLogout } from "react-icons/md";
import { clearUserAction } from "../../../redux/actions/userActions/userActions";


const LogOut = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearUserAction());
  }, []);

  return (
    <>
      <div className="flex justify-center items-center text-center h-screen w-full">
        <span className="flex flex-col justify-center items-center text-3xl font-medium space-y-5 text-sky-200">
          <MdOutlineLogout size={80} />
          <p>لطفا منتظر بمانید!</p>
          <p>شما درحال خروج از حساب کاربری می باشید</p>
        </span>
      </div>
    </>
  );
};

export default LogOut;
