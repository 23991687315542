import { isEmpty } from "lodash";
import { Routes, Route, Navigate } from "react-router-dom";

import { decodeJWT } from "../../utils";

import SignIn from "../../components/Auth/SignIn/SignIn";
import SignUp from "../../components/Auth/SignUp/SignUp";
import LogOut from "../../components/Auth/LogOut/LogOut";
import ForgetPassword from "../../components/Auth/ForgetPassword/ForgetPassword";

import AuthContext from "../../context/AuthContext/AuthContext";

const AuthContainer = () => {
  const token = localStorage.getItem("token");

  const isLogin = !isEmpty(token);

  const decodedToken = decodeJWT(token);

  const redirectUrl = `/${
    decodedToken?.usertype === "ADMIN" ? "Public/Home" : "Public/Home"
  }`;
  return (
    <>
      <AuthContext>
        <Routes>
          {/* Sign In */}
          <Route
            path="/"
            // element={<SignIn />}
            element={isLogin ? <Navigate to={redirectUrl} /> : <SignIn />}
          />
          {/* Sign Up */}
          <Route
            path="/Sign-Up"
            // element={<SignUp />}
            element={isLogin ? <Navigate to={redirectUrl} /> : <SignUp />}
          />
          {/* Forget Password */}
          <Route
            path="/Forget-Password"
            element={
              isLogin ? <Navigate to={redirectUrl} /> : <ForgetPassword />
            }
          />
          {/* Log Out */}
          <Route path="/Log-Out" element={<LogOut />} />
        </Routes>
      </AuthContext>
    </>
  );
};

export default AuthContainer;
