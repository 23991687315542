import { createContext, useState } from "react";
import { useDispatch } from "react-redux";

import {
  toastErrorMessage,
  toastSuccessMessage,
} from "../../../utils/toastMessage/toastMessage";

import {
  setLoadingFalseAction,
  setLoadingTrueAction,
} from "../../../redux/actions/loadingActions/loadingActions";

import {
  changeUserActiveServices,
  createUsersAdminService,
  deleteUsersAdminService,
  editUserAdminServices,
  editUserToleranceAdminServices,
  getProductsListPublicService,
  getUserToleranceAdminService,
  getUsersAdminService,
} from "../../../services/userServices";

export const usersAdminContext = createContext({
  dataItems: [],
  setDataItems: () => {},
  getUsers: [],
  setGetUsers: () => {},
  addUser: null,
  setAddUser: () => {},
  userId: "",
  setUserId: () => {},
  handleCreateUserAdmin: () => {},
  handleGetUsersAdmin: () => {},
  handleOpenEditModal: () => {},
  handleCloseEditModal: () => {},
  handleEditUserAdmin: () => {},
  handleChangeActiveUser: () => {},
  handleDeleteUser: () => {},
  // * Tolerance Part
  getProducts: [],
  setGetProducts: () => {},
  userID: "",
  setUserID: () => {},
  handleGetProducts: () => {},
  handleGetUserTolerance: () => {},
  handleOpenEditModalUserTolerance: () => {},
  handleCloseEditModalUserTolerance: () => {},
  handleEditUserToleranceAdmin: () => {},
  handleBuyToleranceChange: () => {},
  handleSellToleranceChange: () => {},
  isOpenInfo: null,
  setIsOpenInfo: () => {},
  productName: "",
  setProductName: () => {},
  bTolerance: "",
  setBTolerance: () => {},
  sTolerance: "",
  setSTolerance: () => {},
});

const UsersAdminContext = ({ children }) => {
  const dispatch = useDispatch();
  const [dataItems, setDataItems] = useState([]);
  const [getUsers, setGetUsers] = useState([]);
  const [addUser, setAddUser] = useState(false);
  const [userId, setUserId] = useState("");
  const [isAllowed] = useState(false);
  // * Tolerance Part
  const [getProducts, setGetProducts] = useState([]);
  const [userID, setUserID] = useState("");
  const [isOpenInfo, setIsOpenInfo] = useState(false);
  const [product, setProduct] = useState("");
  const [productName, setProductName] = useState("");
  const [bTolerance, setBTolerance] = useState("");
  const [sTolerance, setSTolerance] = useState("");
  const [id, setId] = useState("");

  // Handle Create User Admin
  const handleCreateUserAdmin = async (userData) => {
    const objData = {
      ...userData,
      gender: Number(userData.gender),
      isAllowed,
    };
    try {
      dispatch(setLoadingTrueAction());
      const { status } = await createUsersAdminService(objData);
      if (status === 201) {
        setAddUser(false);
        handleGetUsersAdmin();
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
      dispatch(setLoadingFalseAction());
    }
  };

  // Handle Get Users Admin
  const handleGetUsersAdmin = async () => {
    try {
      dispatch(setLoadingTrueAction());
      const { status, data } = await getUsersAdminService();
      if (status === 200) {
        setGetUsers(data);
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    } finally {
      dispatch(setLoadingFalseAction());
    }
  };

  // Handle Edit Product Admin
  const handleOpenEditModal = (_item, setValueEditUser) => {
    setUserId(_item.id);

    setValueEditUser("id", _item.id);
    setValueEditUser("firstname", _item.firstname);
    setValueEditUser("lastname", _item.lastname);
    setValueEditUser("phonenumber", _item.phonenumber);
    setValueEditUser("nationalid", _item.nationalid);
    setValueEditUser("gender", _item.gender);
    setValueEditUser("user_type", _item.user_type);
    setValueEditUser("isAllow", _item.isAllowed);
  };

  const handleCloseEditModal = () => {
    setUserId("");
  };

  const handleEditUserAdmin = async (userData) => {
    const objData = {
      ...userData,
      gender: Number(userData.gender),
    };

    try {
      dispatch(setLoadingTrueAction());
      const { status } = await editUserAdminServices(objData);
      if (status === 200) {
        handleCloseEditModal();
        handleGetUsersAdmin();
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    } finally {
      dispatch(setLoadingFalseAction());
    }
  };

  // Change Active User
  const handleChangeActiveUser = async (id, isAllowed) => {
    const objData = { id, isAllowed };

    try {
      dispatch(setLoadingTrueAction());
      const { status } = await changeUserActiveServices(objData);
      if (status === 200) {
        handleGetUsersAdmin();
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
      dispatch(setLoadingFalseAction());
    }
  };

  // Handle Delete User Context
  const handleDeleteUser = async (id) => {
    try {
      dispatch(setLoadingTrueAction());
      const { status } = await deleteUsersAdminService(id);
      if (status === 200) {
        handleGetUsersAdmin();
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
      dispatch(setLoadingFalseAction());
    }
  };

  // * Tolerance Part

  const addCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // Handle Get Products Admin
  const handleGetProducts = async () => {
    try {
      const { status, data } = await getProductsListPublicService();
      if (status === 200) {
        setGetProducts(data);
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    }
  };

  // Handle Get User Tolerance Admin
  const handleGetUserTolerance = async (event) => {
    event.preventDefault();

    const objData = {
      productName,
      userID,
    };

    try {
      const { status, data } = await getUserToleranceAdminService(objData);
      if (status === 200) {
        setIsOpenInfo(true);
        setBTolerance(data[0]?.buy_telorance);
        setSTolerance(data[0]?.sell_telorance);
        setId(data[0]?.id);
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    }
  };

  const handleBuyToleranceChange = (e) => {
    const value = e.target.value.replace(/,/g, "");
    setBTolerance(addCommas(value));
  };

  const handleSellToleranceChange = (e) => {
    const value = e.target.value.replace(/,/g, "");
    setSTolerance(addCommas(value));
  };

  // Handle Edit User Tolerance Admin
  const handleOpenEditModalUserTolerance = (_item) => {
    setUserID(_item.id);
    setBTolerance(bTolerance);
    setSTolerance(sTolerance);
  };

  const handleCloseEditModalUserTolerance = () => {
    setIsOpenInfo(false);
    setUserID("");
  };

  const handleEditUserToleranceAdmin = async (event) => {
    event.preventDefault();


      // Ensure that bTolerance and sTolerance are strings
    const buyToleranceStr = typeof bTolerance === 'string' ? bTolerance : String(bTolerance);
    const sellToleranceStr = typeof sTolerance === 'string' ? sTolerance : String(sTolerance);

    const buyTolerance = parseFloat(buyToleranceStr.replace(/,/g, ""));
    const sellTolerance = parseFloat(sellToleranceStr.replace(/,/g, ""));


    // cons t buyTolerance = parseFloat(bTolerance.replace(/,/g, ""));
    // const sellTolerance = parseFloat(sTolerance.replace(/,/g, ""));

    const objData = {
      buy_telorance: buyTolerance,
      sell_telorance: sellTolerance,
    };

    try {
      dispatch(setLoadingTrueAction());
      const { status } = await editUserToleranceAdminServices(objData, id);
      if (status === 200) {
        setProductName("");
        setBTolerance("");
        setSTolerance("");
        toastSuccessMessage("تغییرات شما با موفقیت ذخیره شد");
        handleGetUsersAdmin();
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    } finally {
      dispatch(setLoadingFalseAction());
    }
  };

  return (
    <usersAdminContext.Provider
      value={{
        dataItems,
        setDataItems,
        getUsers,
        setGetUsers,
        addUser,
        setAddUser,
        userId,
        setUserId,
        handleCreateUserAdmin,
        handleGetUsersAdmin,
        handleOpenEditModal,
        handleCloseEditModal,
        handleEditUserAdmin,
        handleChangeActiveUser,
        handleDeleteUser,
        // * Tolerance Part
        isOpenInfo,
        setIsOpenInfo,
        product,
        setProduct,
        getProducts,
        setGetProducts,
        userID,
        setUserID,
        handleGetProducts,
        handleGetUserTolerance,
        handleOpenEditModalUserTolerance,
        handleCloseEditModalUserTolerance,
        handleEditUserToleranceAdmin,
        addCommas,
        productName,
        setProductName,
        bTolerance,
        handleBuyToleranceChange,
        sTolerance,
        handleSellToleranceChange,
      }}
    >
      {children}
    </usersAdminContext.Provider>
  );
};

export default UsersAdminContext;
