import axios from "axios";

import { deliverySuccessMessage, toastErrorMessage, toastOfflineMessage } from "../../utils/toastMessage/toastMessage";

// Axios Setting
// Common Headers
const token = localStorage.getItem("token");

if (token) axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

axios.defaults.headers.common["Accept"] = "application/json";
// End

// Post Headers
axios.defaults.headers.post["Content-Type"] = "application/json";
// End

// Check internet connection
window.addEventListener("offline", () => toastOfflineMessage());
window.addEventListener("online", () =>
  deliverySuccessMessage("شما به اینترنت متصل شدید", 3000)
);

if (navigator.onLine === false) toastOfflineMessage();
// End

axios.interceptors.response.use(null, (error) => {
  const expectedErrors =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedErrors) {
    toastErrorMessage("از سمت سرور مشکلی پیش آمده");
  }
  return Promise.reject(error);
});

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
};
