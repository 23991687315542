import { decodeJWT } from "../../../utils";


export const setUserAction = (token) => {
    return async (dispatch) => {
        localStorage.setItem("token", token);

        const decodedToken = decodeJWT(token);
        const role = decodedToken?.usertype;

        await dispatch({
            type: "SET_USER",
            payload: {
                token,
                isAdmin: role === "ADMIN",
                role,
                phonenumber: decodedToken?.phonenumber,
            },
        });
    };
};

export const clearUserAction = () => {
    return async (dispatch) => {
        localStorage.clear();

        window.location.href = "/";

        await dispatch({ type: "CLEAR_USER" });
    };
};
