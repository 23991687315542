import { Link } from "react-router-dom";

import { logo } from "../../../assets";

import {
  HiOutlineShoppingBag,
  HiOutlineUserGroup,
  HiOutlineNewspaper,
  HiExclamation,
} from "react-icons/hi";

const AdminMobileMenu = () => {
  return (
    <div
      className="lg:hidden inline-flex
      fixed bottom-2 left-1/2 transform
      -translate-x-1/2 z-50 mx-auto
      justify-between w-10/12 rounded-3xl
      bg-[#1d1f25] bg-opacity-60 hover:bg-opacity-100
      transition-all duration-100 ease-linear
      hover:transition-all hover:duration-100 hover:ease-linear
      "
    >
      {/* 1 */}
      <Link
        to="/Admin/Dashboard/News"
        className="inline-flex flex-col items-center 
         text-[#A79B81] hover:text-amber-300 py-3
         -px-4 flex-grow"
      >
        <HiOutlineNewspaper size={30} />
      </Link>
      {/* 2 */}
      <Link
        to="/Admin/Dashboard/Users"
        className="inline-flex flex-col items-center 
      text-[#A79B81] hover:text-amber-300 py-3
        -px-4 flex-grow"
      >
        <HiOutlineUserGroup size={30} />
      </Link>
      {/* 3 */}
      <button
        className="relative inline-flex flex-col
        items-center py-3 px-6 flex-grow"
      >
        <Link
          to="/Public/Home"
          className="absolute bottom-4 rounded-full
          border-b-[4px] border-r-[5px] border-l-[5px]
         border-[#252839] bg-transparent"
        >
          <img
            src={logo}
            alt="Website Logo"
            width={60}
            className="rounded-full bg-cover"
          />
        </Link>
      </button>
      {/* 4 */}
      <Link
        to="/Admin/Dashboard/Products"
        className="inline-flex flex-col items-center 
      text-[#A79B81] hover:text-amber-300 py-3
        -px-4 flex-grow"
      >
        <HiOutlineShoppingBag size={30} />
      </Link>
      {/* 5 */}
      <Link
        to="/Admin/Dashboard/Requests"
        className="inline-flex flex-col items-center 
      text-[#A79B81] hover:text-amber-300 py-3
        -px-4 flex-grow"
      >
        <HiExclamation size={30} />
      </Link>
    </div>
  );
};

export default AdminMobileMenu;
