import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import { logo } from "../../../assets";

import {
  HiOutlineHome,
  HiOutlineShoppingBag,
  HiOutlineUserCircle,
  HiOutlineUserGroup,
  HiOutlineNewspaper,
  HiOutlineLogin,
} from "react-icons/hi";

const AdminPcMenu = () => {
  const userInfo = useSelector((state) => state.userInfo);

  return (
    <>
      <div className="lg:flex hidden">
        <div
          className="ml-[-100%] fixed z-10 top-0 pb-3 px-6 w-full flex flex-col 
          justify-between h-screen border-l border-gray-600 bg-[#1A1D27] 
          transition duration-300 2xl:w-[15%] xl:w-[25%] lg:w-[30%] md:w-4/12 sm:w-6/12"
        >
          <div>
            {/* Logo */}
            <div className="flex justify-center py-4">
              <Link to="/Public/Home">
                <img
                  src={logo}
                  width={70}
                  className="rounded-full bg-cover"
                  alt="Website Logo"
                />
              </Link>
            </div>
            {/* Profile */}
            <div className="mt-8 text-center">
              <h5 className="hidden mt-4 text-xl font-semibold text-slate-200 lg:block">
                {userInfo[0]?.firstname} {userInfo[0]?.lastname}
              </h5>
              <span className="hidden text-gray-400 lg:block">ادمین</span>
            </div>
            {/* Buttons */}
            <ul className="space-y-3 tracking-wide mt-8">
              {/* 1 */}
              <li>
                <NavLink
                  to="/Admin/Dashboard/Profile"
                  className={({ isActive }) =>
                    isActive
                      ? "relative px-4 py-3 flex items-center space-x-4 bg-sky-900 rounded-xl text-white cursor-pointer"
                      : "relative px-4 py-3 flex items-center space-x-4 rounded-xl text-white cursor-pointer"
                  }
                >
                  <HiOutlineUserCircle
                    size={25}
                    className="text-slate-200 ml-2"
                  />
                  <span className="-mr-1 font-medium">پروفایل</span>
                </NavLink>
              </li>
              {/* 2 */}
              <li>
                <NavLink
                  to="/Admin/Dashboard/Users"
                  className={({ isActive }) =>
                    isActive
                      ? "relative px-4 py-3 flex items-center space-x-4 bg-sky-900 rounded-xl text-white cursor-pointer"
                      : "relative px-4 py-3 flex items-center space-x-4 rounded-xl text-white cursor-pointer"
                  }
                >
                  {" "}
                  <HiOutlineUserGroup
                    size={25}
                    className="text-slate-200 ml-2"
                  />
                  <span className="-mr-1 font-medium">کاربران</span>
                </NavLink>
              </li>
              {/* 3 */}
              <li>
                <NavLink
                  to="/Admin/Dashboard/Products"
                  className={({ isActive }) =>
                    isActive
                      ? "relative px-4 py-3 flex items-center space-x-4 bg-sky-900 rounded-xl text-white cursor-pointer"
                      : "relative px-4 py-3 flex items-center space-x-4 rounded-xl text-white cursor-pointer"
                  }
                >
                  <HiOutlineShoppingBag
                    size={25}
                    className="text-slate-200 ml-2"
                  />
                  <span className="group-hover:text-gray-700">محصولات</span>
                </NavLink>
              </li>
              {/* 4 */}
              <li>
                <NavLink
                  to="/Admin/Dashboard/Requests"
                  className={({ isActive }) =>
                    isActive
                      ? "relative px-4 py-3 flex items-center space-x-4 bg-sky-900 rounded-xl text-white cursor-pointer"
                      : "relative px-4 py-3 flex items-center space-x-4 rounded-xl text-white cursor-pointer"
                  }
                >
                  <HiOutlineLogin
                    size={25}
                    className="text-slate-200 ml-2 rotate-180"
                  />
                  <span className="group-hover:text-gray-700">
                    درخواست های ورود
                  </span>
                </NavLink>
              </li>
              {/* 5 */}
              <li>
                <NavLink
                  to="/Admin/Dashboard/News"
                  className={({ isActive }) =>
                    isActive
                      ? "relative px-4 py-3 flex items-center space-x-4 bg-sky-900 rounded-xl text-white cursor-pointer"
                      : "relative px-4 py-3 flex items-center space-x-4 rounded-xl text-white cursor-pointer"
                  }
                >
                  <HiOutlineNewspaper
                    size={25}
                    className="text-slate-200 ml-2 rotate-180"
                  />
                  <span className="group-hover:text-gray-700">اخبار</span>
                </NavLink>
              </li>
            </ul>
          </div>
          {/* Exit Button */}
          <div className="px-5 -mx-6 pt-4 flex items-center border-t border-gray-600">
            <Link to="/Public/Home">
              <button className="px-4 py-3 flex items-center space-x-4 rounded-md text-slate-200 hover:text-gray-400">
                <HiOutlineHome size={25} className="ml-2" />
                <span>صفحه اصلی</span>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminPcMenu;
