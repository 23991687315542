import { createContext, useState } from "react";
import { useDispatch } from "react-redux";

import { toastErrorMessage } from "../../../utils/toastMessage/toastMessage";

import {
  setLoadingFalseAction,
  setLoadingTrueAction,
} from "../../../redux/actions/loadingActions/loadingActions";

import {
  getProductsListPublicService,
  postTransactionPublicService,
} from "../../../services/userServices";

export const pricePublicContext = createContext({
  confirmModal: null,
  setConfirmModal: () => {},
  resultModal: null,
  setResultModal: () => {},
  data: [],
  setData: () => {},
  type: "",
  setType: () => {},
  change: "",
  stateWeight: "",
  setStateWeight: () => {},
  statePrice: "",
  setStatePrice: () => {},
  stateTotal: "",
  setStateTotal: () => {},
  counter: "",
  setCounter: () => {},
  bos: "",
  setBos: () => {},
  transactionId: "",
  setTransactionId: () => {},
  product_name: "",
  setProduct_name: () => {},
  customeruser: "",
  setCustomeruser: () => {},
  description: "",
  setDescription: () => {},
  is_orderi: null,
  setIs_orderi: () => {},
  // Price List Start
  getItemsList: [],
  setGetItemsList: () => {},
  handleGetItemsList: () => {},
  // Price List End
  // Transaction End
  handlePostTransaction: () => {},
  // Transaction Start
  handlePriceChange: () => {},
  handleWeightChange: () => {},
  handleTotalChange: () => {},
  addCommas: () => {},
  // News Start
  openModal: null,
  setOpenModal: () => {},
});

const PricePublicContext = ({ children }) => {
  const dispatch = useDispatch();

  const [type, setType] = useState("buy");
  const [change] = useState("changed");
  const [data, setData] = useState([]);
  const [getItemsList, setGetItemsList] = useState([]);

  const [stateWeight, setStateWeight] = useState("");
  const [statePrice, setStatePrice] = useState("");
  const [stateTotal, setStateTotal] = useState("");

  const [bos, setBos] = useState("");
  const [tType, setTtype] = useState("E");
  const [is_orderi, setIs_orderi] = useState(true);
  const [description, setDescription] = useState("");
  const [product_name, setProduct_name] = useState("");
  const [transactionId, setTransactionId] = useState("");

  const [customeruser, setCustomeruser] = useState("");

  const [confirmModal, setConfirmModal] = useState(false);
  const [resultModal, setResultModal] = useState(false);
  const [counter, setCounter] = useState(0);

  const [openModal, setOpenModal] = useState(false);

  // Handle Post Transaction Public
  const handlePostTransaction = async (e) => {
    e?.preventDefault();

    const objData = {
      stateTotal,
      stateWeight,
      statePrice,
      tType,
      description,
      bos,
      is_orderi,
      product_name,
      customeruser,
    };
    try {
      dispatch(setLoadingTrueAction());
      const { status } = await postTransactionPublicService(objData)
        .then((response) => {
          setResultModal(true);
          setConfirmModal(false);
          setTransactionId(response.data.transactionID);
        })
        .catch((error) => {
          // console.error("Error:", error);
        });
      if (status === 200) {
        handleGetItemsList();
        setStatePrice("");
        setStateWeight("");
        setStateTotal("");
        setBos("");
        setTtype("");
        setIs_orderi("");
        setDescription("");
        setProduct_name("");
        setCustomeruser("");
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
      dispatch(setLoadingFalseAction());
    }
  };

  // Handle Prices
  const handlePriceChange = (_item, e) => {
    e.preventDefault();
    const price = parseFloat(e.target.value.replace(/,/g, ""));
    const pricePerOne = isNaN(price)
      ? 0
      : _item.tag === "T"
      ? price / 4.3318
      : price;
    const weight = stateWeight;
    const total = (pricePerOne * weight).toFixed(0);
    setStatePrice(isNaN(price) ? 0 : price);
    setStateTotal(total);
  };

  // Handle Weight
  const handleWeightChange = (_item, e) => {
    e.preventDefault();
    const pricePerOne = isNaN(statePrice)
      ? 0
      : _item.tag === "T"
      ? statePrice / 4.3318
      : statePrice;
    const inputValue = e.target.value;

    // If _item.tag is "S", ensure that the input is a whole number
    if (_item.tag === "S" && !/^\d+$/.test(inputValue)) {
      // If the input is not a whole number, don't update the state
      return;
    }

    // Check if the input is a valid number format (including the decimal point)
    if (/^\d*\.?\d*$/.test(inputValue)) {
      // Update state with the input value
      let limitedValue = inputValue;
      if (inputValue.includes(".")) {
        const parts = inputValue.split(".");
        if (parts[1].length >= 4) {
          return;
        } else {
          limitedValue = `${parts[0]}.${parts[1].slice(0, 3)}`;
        }
      }
      setStateWeight(limitedValue);
      const weight = parseFloat(inputValue);
      const total = (pricePerOne * (isNaN(weight) ? 0 : weight)).toFixed(0);
      setStateTotal(total);
    }
  };

  // Handle Total
  const handleTotalChange = (_item, e) => {
    e.preventDefault();
    const total = parseFloat(e.target.value.replace(/,/g, ""));
    let pricePerOne = 0;
    if (_item.tag === "T") {
      pricePerOne = isNaN(statePrice) ? 0 : statePrice / 4.3318;
      const weight = total / pricePerOne;
      setStateTotal(isNaN(total) ? 0 : total);
      setStateWeight(isNaN(weight) ? 0 : weight.toFixed(3));
    } else {
      pricePerOne = isNaN(statePrice) ? 0 : statePrice;
    }
  };

  // Handle Add Comma For Number
  const addCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // Handle Get Items List
  const handleGetItemsList = async () => {
    try {
      const { status, data } = await getProductsListPublicService();
      if (status === 200) {
        setGetItemsList(data);
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    }
  };

  return (
    <pricePublicContext.Provider
      value={{
        is_orderi,
        setIs_orderi,
        description,
        setDescription,
        customeruser,
        setCustomeruser,
        product_name,
        setProduct_name,
        confirmModal,
        setConfirmModal,
        resultModal,
        setResultModal,
        data,
        setData,
        getItemsList,
        setGetItemsList,
        type,
        setType,
        change,
        stateWeight,
        setStateWeight,
        bos,
        setBos,
        statePrice,
        setStatePrice,
        stateTotal,
        setStateTotal,
        counter,
        setCounter,
        transactionId,
        setTransactionId,
        handleGetItemsList,
        handlePostTransaction,
        handlePriceChange,
        handleWeightChange,
        handleTotalChange,
        addCommas,
        openModal,
        setOpenModal,
      }}
    >
      {children}
    </pricePublicContext.Provider>
  );
};

export default PricePublicContext;
