import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import PersianNumber from "../../../utils/PersianNumber/PersianNumber";

import { IoArrowUpOutline, IoArrowDownOutline } from "react-icons/io5";
import { MdOutlineCurrencyExchange } from "react-icons/md";
import { TbClockCancel } from "react-icons/tb";
import { TbLoader } from "react-icons/tb";

import { pricePublicContext } from "../../../context/PublicContext/PricePublicContext/PricePublicContext";

import { useState } from "react";

const PcPriceView = () => {
  const userInfo = useSelector((state) => state.userInfo);
  const [loading, setLoading] = useState(true);

  const {
    setIs_orderi,
    description,
    setDescription,
    setCustomeruser,
    setProduct_name,
    confirmModal,
    setConfirmModal,
    resultModal,
    setResultModal,
    data,
    setData,
    getItemsList,
    type,
    setType,
    stateWeight,
    setStateWeight,
    setBos,
    transactionId,
    statePrice,
    setStatePrice,
    stateTotal,
    setStateTotal,
    setCounter,
    handleGetItemsList,
    handlePostTransaction,
    handlePriceChange,
    handleWeightChange,
    handleTotalChange,
    addCommas,
  } = useContext(pricePublicContext);

  const handleInitial = async () => {
    setCounter((prevCounter) => prevCounter + 1);
    await handleGetItemsList();
  };

  useEffect(() => {
    const fetchData = async () => {
      await handleInitial();
      setLoading(false);
    };
    fetchData();

    const interval = setInterval(handleInitial, 1000);
    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return (
      <span
        className="mr-auto 2xl:w-[100%] xl:w-[95%] lg:w-[90%]
      py-3 absolute top-[50%] mt-10 text-slate-200 text-xl
      flex flex-col justify-center items-center"
      >
        <span>
          <TbLoader size={60} />
        </span>
        <p>لطفا صبر کنید</p>
      </span>
    );
  }

  return (
    <>
      <section>
        {isEmpty(getItemsList) ? (
          <>
            <span
              className="mr-auto 2xl:w-[100%] xl:w-[95%] lg:w-[90%]
              py-3 absolute top-[50%] mt-10 text-slate-200 text-xl
              flex flex-col justify-center items-center"
            >
              <span>
                <TbClockCancel size={60} />
              </span>
              <p>سامانه بسته می باشد</p>
            </span>
          </>
        ) : (
          <>
            {/* Box View */}
            <div className="flex justify-center w-full">
              <div
                className="grid 2xl:grid-cols-4 xl:grid-cols-3 grid-cols-2
                gap-y-16 py-8 2xl:justify-items-center justify-items-end 
                px-2 2xl:mr-0 mr-10 w-full
                animate__animated animate__fadeIn animate__slow"
              >
                {getItemsList.map((_item) => (
                  <>
                    {_item.periority === true && (
                      <div key={_item.id}>
                        <div
                          className="h-80 w-[18rem] rounded-xl bg-[#1a1d2783] 
                     shadow-BoxShadow max-w-lg relative mx-8 py-2"
                        >
                          {/* Modal */}
                          {_item.id === data.id && (
                            <div
                              className="absolute top-0 left-0 right-0 h-full z-30
                          animate__animated animate__fadeIn animate__fast"
                            >
                              <div className="flex flex-col justify-around items-center w-full h-full">
                                {/* Start Buy/Sell Modal */}
                                {!confirmModal && !resultModal && (
                                  <div className="flex flex-col justify-around items-center w-full h-full pt-4">
                                    <>
                                      {/* Order Icon */}
                                      {type === "buy" ? (
                                        <>
                                          {_item.buy_price !== statePrice && (
                                            <span
                                              className="absolute px-2 py-1 rounded-full top-1
                                left-1 flex items-center text-slate-300"
                                            >
                                              <div className="group flex relative">
                                                <MdOutlineCurrencyExchange
                                                  size={18}
                                                  className="animate-spin-slow"
                                                />
                                                <span
                                                  className="group-hover:opacity-100 transition-opacity 
                                 px-1 text-sm text-slate-300 rounded-md absolute left-1/2 
                                 -translate-x-1/2 translate-y-full opacity-0 m-1 mx-auto"
                                                >
                                                  اردری
                                                </span>
                                              </div>
                                            </span>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {_item.sell_price !== statePrice && (
                                            <span
                                              className="absolute px-2 py-1 rounded-full top-1 
                                left-1 flex items-center text-slate-300"
                                            >
                                              <div className="group flex relative">
                                                <MdOutlineCurrencyExchange
                                                  size={18}
                                                  className="animate-spin-slow"
                                                />
                                                <span
                                                  className="group-hover:opacity-100 transition-opacity 
                               px-1 text-sm text-slate-300 rounded-md absolute left-1/2 
                               -translate-x-1/2 translate-y-full opacity-0 m-1 mx-auto"
                                                >
                                                  اردری
                                                </span>
                                              </div>
                                            </span>
                                          )}
                                        </>
                                      )}
                                      {/* Price */}
                                      <span className="relative px-5 w-[80%]">
                                        <input
                                          type="text"
                                          autoComplete="off"
                                          name="price"
                                          value={
                                            statePrice === -1
                                              ? ""
                                              : statePrice === ""
                                              ? statePrice + statePrice
                                              : addCommas(statePrice)
                                          }
                                          onChange={(e) => {
                                            handlePriceChange(_item, e);
                                          }}
                                          className={`${
                                            type === "buy"
                                              ? "focus:text-green-500 focus:border-green-600"
                                              : "focus:text-red-500 focus:border-red-600"
                                          } block py-2 px-2 w-full text-sm bg-transparent border-0 border-b-2
                           border-zinc-500 appearance-none text-slate-500  
                             focus:transition-all focus:duration-200 focus:ease-linear 
                             transition-all duration-200 ease-linear focus:outline-0
                             focus:outline-none focus:ring-0 peer text-left
                          `}
                                          style={{ direction: "ltr" }}
                                          placeholder=" "
                                        />
                                        <label
                                          htmlFor="price"
                                          className={`${
                                            type === "buy"
                                              ? "peer-focus:text-green-600"
                                              : "peer-focus:text-red-600"
                                          } absolute text-xl text-gray-400
                          duration-300 transform  -translate-y-6 scale-75
                          right-0 top-1 -z-10 peer-focus:right-0 flex w-full justify-between
                          peer-placeholder-shown:translate-y-0 peer-focus:-translate-y-5
                            `}
                                        >
                                          {_item.tag === "S" ? "قیمت" : "مظنه"}
                                        </label>
                                      </span>
                                      {/* Weight */}
                                      <span className="relative px-5 w-[80%]">
                                        <input
                                          type="text"
                                          autoComplete="off"
                                          name="weight"
                                          value={stateWeight}
                                          onChange={(e) => {
                                            handleWeightChange(_item, e);
                                          }}
                                          className={`${
                                            type === "buy"
                                              ? "focus:text-green-500 focus:border-green-600"
                                              : "focus:text-red-500 focus:border-red-600"
                                          } block py-2 px-2 w-full text-sm bg-transparent border-0 border-b-2
                                        border-zinc-500 appearance-none text-slate-500  
                                        focus:transition-all focus:duration-200 focus:ease-linear 
                                        transition-all duration-200 ease-linear focus:outline-0
                                        focus:outline-none focus:ring-0 peer text-left
                                      `}
                                          placeholder=" "
                                        />
                                        <label
                                          htmlFor="weight"
                                          className={`${
                                            type === "buy"
                                              ? "peer-focus:text-green-600"
                                              : "peer-focus:text-red-600"
                                          } absolute text-xl text-gray-400
                          duration-300 transform  -translate-y-6 scale-75
                          right-0 top-1 -z-10 peer-focus:right-0 px-5
                          peer-placeholder-shown:translate-y-0 peer-focus:-translate-y-5
                            `}
                                        >
                                          {_item.tag === "S"
                                            ? "تعداد"
                                            : "مقدار (گرم)"}
                                        </label>
                                      </span>
                                      {/* Total */}
                                      <span className="relative px-5 w-[80%]">
                                        <input
                                          type="text"
                                          autoComplete="off"
                                          name="total"
                                          value={addCommas(stateTotal)}
                                          onChange={(e) => {
                                            handleTotalChange(_item, e);
                                          }}
                                          className={`${
                                            type === "buy"
                                              ? "focus:text-green-500 focus:border-green-600"
                                              : "focus:text-red-500 focus:border-red-600"
                                          } block py-2 px-2 w-full text-sm bg-transparent border-0 border-b-2
                          border-zinc-500 appearance-none text-slate-500  
                            focus:transition-all focus:duration-200 focus:ease-linear 
                            transition-all duration-200 ease-linear focus:outline-0
                            focus:outline-none focus:ring-0 peer text-left
                          `}
                                          placeholder=" "
                                        />
                                        <label
                                          htmlFor="total"
                                          className={`${
                                            type === "buy"
                                              ? "peer-focus:text-green-600"
                                              : "peer-focus:text-red-600"
                                          } absolute text-xl text-gray-400
                          duration-300 transform  -translate-y-6 scale-75
                          right-0 top-1 -z-10 peer-focus:right-0 px-5
                          peer-placeholder-shown:translate-y-0 peer-focus:-translate-y-5
                            `}
                                        >
                                          جمع کل
                                        </label>
                                      </span>
                                      {/* Description */}
                                      <span className="relative px-5 w-[80%]">
                                        <input
                                          type="text"
                                          autoComplete="off"
                                          name="description"
                                          value={description}
                                          onChange={(e) =>
                                            setDescription(e.target.value)
                                          }
                                          className={`${
                                            type === "buy"
                                              ? "focus:text-green-500 focus:border-green-600"
                                              : "focus:text-red-500 focus:border-red-600"
                                          } block py-1.5 px-2 w-full text-sm bg-transparent border-0 border-b-2
                          border-zinc-500 appearance-none text-slate-500  
                            focus:transition-all focus:duration-200 focus:ease-linear 
                            transition-all duration-200 ease-linear focus:outline-0
                            focus:outline-none focus:ring-0 peer text-right
                          `}
                                          placeholder=" "
                                        />
                                        <label
                                          htmlFor="description"
                                          className={`${
                                            type === "buy"
                                              ? "peer-focus:text-green-600"
                                              : "peer-focus:text-red-600"
                                          } absolute text-xl text-gray-400
                          duration-300 transform  -translate-y-6 scale-75
                          right-0 top-1 -z-10 peer-focus:right-0 px-5
                          peer-placeholder-shown:translate-y-0 peer-focus:-translate-y-5
                            `}
                                        >
                                          توضیحات
                                        </label>
                                      </span>
                                      {/* Button */}
                                      <span className="flex justify-center w-[50%]">
                                        <button
                                          disabled={confirmModal ? true : false}
                                          className="text-slate-300 text-base tracking-wider mx-4"
                                          onClick={() => {
                                            setStatePrice("");
                                            setStateWeight("");
                                            setStateTotal("");
                                            setDescription("");
                                            setData("");
                                            setType("");
                                            setConfirmModal(false);
                                            setResultModal(false);
                                          }}
                                        >
                                          لغو
                                        </button>
                                        <button
                                          onClick={() => {
                                            setConfirmModal(true);
                                            setResultModal(false);
                                          }}
                                          className={`${
                                            type === "buy"
                                              ? "bg-green-500 hover:bg-green-600"
                                              : "bg-red-500 hover:bg-red-600"
                                          } rounded-full px-3 py-1 mx-1
                            tracking-wider text-base text-white
                            transition-all duration-100 ease-linear
                            hover:transition-all hover:duration-100 hover:ease-linear`}
                                        >
                                          {type === "buy" ? "خرید" : "فروش"}
                                        </button>
                                      </span>
                                    </>
                                  </div>
                                )}
                                {/* End Buy/Sell Modal */}

                                {/* Start Confirm Modal */}
                                {confirmModal && !resultModal && (
                                  <form
                                    onSubmit={handlePostTransaction}
                                    className="absolute flex flex-col 
                                    items-center justify-around w-full h-full"
                                  >
                                    <div
                                      className="flex text-slate-300 w-full h-full 
                                  flex-col justify-center px-2 space-y-4
                                  animate__animated animate__fadeIn animate__fast"
                                    >
                                      {/* Order Type Row */}
                                      <span className="flex justify-center items-center w-full">
                                        <span className="font-bold tracking-wider px-2">
                                          نوع عملیات :
                                        </span>
                                        <span className="font-medium tracking-wider text-zinc-300">
                                          {type === "buy" ? (
                                            <span className="text-green-500">
                                              خرید
                                            </span>
                                          ) : (
                                            <span className="text-red-500">
                                              فروش
                                            </span>
                                          )}
                                        </span>
                                      </span>
                                      {/* Price Row */}
                                      <span className="flex justify-center items-center w-full">
                                        <span className="font-bold tracking-wider px-2">
                                          {_item.tag === "S" ? "قیمت" : "مظنه"}{" "}
                                          :
                                        </span>
                                        <span className="font-medium tracking-wider text-zinc-300">
                                          <PersianNumber
                                            value={statePrice}
                                            thousandSeparator=","
                                          />
                                        </span>
                                      </span>
                                      {/* Weight Row */}
                                      <span className="flex justify-center items-center w-full">
                                        <span className="font-bold tracking-wider px-2">
                                          {_item.tag === "S"
                                            ? "تعداد"
                                            : "مقدار (گرم)"}
                                          :
                                        </span>
                                        <span className="font-medium tracking-wider text-zinc-300">
                                          {_item.tag === "S" ? (
                                            <PersianNumber
                                              value={stateWeight}
                                              thousandSeparator=","
                                            />
                                          ) : (
                                            <PersianNumber
                                              value={stateWeight}
                                              thousandSeparator=","
                                            />
                                          )}
                                        </span>
                                      </span>
                                      {/* Total Row */}
                                      <span className="flex justify-center items-center w-full">
                                        <span className="font-bold tracking-wider px-2">
                                          جمع کل :
                                        </span>
                                        <span className="font-medium tracking-wider text-zinc-300">
                                          <PersianNumber
                                            value={stateTotal}
                                            thousandSeparator=","
                                          />
                                        </span>
                                      </span>
                                      {/* Description Row */}
                                      <span className="flex flex-col justify-center items-center w-full">
                                        <span className="font-bold tracking-wider px-2">
                                          توضیحات :
                                        </span>
                                        <p className="truncate ... w-full text-center font-medium tracking-wider text-zinc-300">
                                          {description}
                                        </p>
                                      </span>
                                      {/* Default Row */}
                                      <span className="flex justify-center items-center w-full">
                                        <span className="font-bold tracking-wider px-2">
                                          {setProduct_name(_item.name)}
                                          {setCustomeruser(userInfo[0]?.id)}
                                          {type === "buy"
                                            ? setBos("B")
                                            : setBos("S")}
                                          {setIs_orderi(
                                            _item.buy_price !== statePrice
                                              ? true
                                              : false
                                          )}
                                        </span>
                                      </span>
                                      {/* Button */}
                                      <span className="flex justify-center">
                                        <button
                                          onClick={() => {
                                            setStatePrice("");
                                            setStateWeight("");
                                            setStateTotal("");
                                            setDescription("");
                                            setData("");
                                            setType("");
                                            setConfirmModal(false);
                                            setResultModal(false);
                                          }}
                                          className="rounded-full px-3 py-1 mx-1
                                  tracking-wider text-base text-slate-300
                                  transition-all duration-100 ease-linear
                                  hover:transition-all hover:duration-100 hover:ease-linear"
                                        >
                                          لغو
                                        </button>
                                        <button
                                          type="submit"
                                          className={`${
                                            type === "buy"
                                              ? "bg-green-500 hover:bg-green-600"
                                              : "bg-red-500 hover:bg-red-600"
                                          } rounded-full px-3 py-0.5 mx-2
                          tracking-wider text-base text-white
                          transition-all duration-100 ease-linear
                          hover:transition-all hover:duration-100 hover:ease-linear`}
                                        >
                                          تأیید
                                        </button>
                                      </span>
                                    </div>
                                  </form>
                                )}
                                {/* End Confirm Modal */}

                                {/* Start Result Modal */}
                                {resultModal && (
                                  <>
                                    {transactionId ? (
                                      <>
                                        <div
                                          className="flex text-slate-300 w-full h-full flex-col justify-center px-2 space-y-5
                              animate__animated animate__fadeIn animate__fast"
                                        >
                                          {/* Rows */}
                                          <span className="flex flex-col items-center space-y-4 w-full">
                                            {/* First Row */}
                                            <span
                                              className={`${
                                                type === "buy"
                                                  ? "text-green-500"
                                                  : "text-red-500"
                                              }
                                  font-bold text-center tracking-wide`}
                                            >
                                              {type === "buy"
                                                ? "خرید "
                                                : "فروش "}
                                              <span className="text-slate-300">
                                                شما به حالت انتظار تغییر پیدا
                                                کرد!
                                              </span>
                                            </span>
                                            {/* Second Row */}
                                            <span className="flex flex-col text-center font-medium tracking-wider text-zinc-300">
                                              <span>شماره سفارش :</span>
                                              <span>#{transactionId}</span>
                                            </span>
                                          </span>
                                          {/* Button */}
                                          <span className="flex justify-center">
                                            <button
                                              onClick={() => {
                                                setStatePrice("");
                                                setStateWeight("");
                                                setStateTotal("");
                                                setData("");
                                                setType("");
                                                setConfirmModal(false);
                                                setResultModal(false);
                                              }}
                                              className="rounded-full px-3 py-1 mx-1
                                  tracking-wider text-base text-white 
                                  bg-green-600 hover:bg-green-700
                                  transition-all duration-100 ease-linear
                                  hover:transition-all hover:duration-100 hover:ease-linear"
                                            >
                                              بستن
                                            </button>
                                          </span>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div
                                          className="flex w-full h-full flex-col 
                                      justify-center space-y-5
                                      animate__animated animate__fadeIn animate__fast"
                                        >
                                          <span
                                            className="flex flex-col items-center w-full
                                        text-slate-300 font-bold text-center leading-loose"
                                          >
                                            <span>سفارش شما ثبت نگردید</span>
                                            <span>لطفا دوباره تلاش کنید!</span>
                                          </span>
                                          {/* Button */}
                                          <span className="flex justify-center">
                                            <button
                                              onClick={() => {
                                                setStatePrice("");
                                                setStateWeight("");
                                                setStateTotal("");
                                                setData("");
                                                setType("");
                                                setConfirmModal(false);
                                                setResultModal(false);
                                              }}
                                              className="rounded-full px-3 py-1 mx-1
                                  tracking-wider text-base text-white 
                                  bg-green-600 hover:bg-green-700
                                  transition-all duration-100 ease-linear
                                  hover:transition-all hover:duration-100 hover:ease-linear"
                                            >
                                              بستن
                                            </button>
                                          </span>
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                                {/* End Result Modal */}
                              </div>
                            </div>
                          )}
                          {/* Information */}
                          <span
                            className={`${
                              _item.id === data.id ? "hidden" : "flex"
                            }
                      flex-col justify-center mt-4 relative space-y-12
                      animate__animated animate__flipInX animate__fast`}
                          >
                            {/* Discount */}
                            <span className="absolute text-xs text-white -top-3 right-4">
                              {_item.change === -1 ? (
                                "-"
                              ) : (
                                <span
                                  className={`${
                                    _item.change < 0
                                      ? "bg-red-500"
                                      : _item.change > 0
                                      ? "bg-green-500"
                                      : _item.change === 0
                                      ? ""
                                      : null
                                  } inline-flex items-center justify-center 
                          rounded-full px-2 py-1 text-xs font-bold 
                          leading-none text-zinc-50`}
                                  style={{ direction: "ltr" }}
                                >
                                  <PersianNumber
                                    value={_item.change}
                                    suffix={"%"}
                                  />
                                </span>
                              )}
                            </span>
                            {/* Last Update */}
                            <span className="absolute text-xs font-bold text-slate-400 -top-14 left-4">
                              <span className="flex text-center text-xs">
                                {_item.last_update_time_ago}
                              </span>
                            </span>
                            {/* Title */}
                            <div className="flex justify-center items-center text-center">
                              <h3 className="font-medium text-xl tracking-tight text-gray-100">
                                {_item.name}
                              </h3>
                            </div>
                            {/* Prices */}
                            <div className="flex flex-col justify-center items-center mx-5">
                              <div
                                className="flex justify-center items-center p-2 w-full
                            text-lg font-normal rounded-xl"
                              >
                                {/* Green Price */}
                                <span className="flex flex-col text-center text-green-600 w-full ml-1">
                                  <span className="flex">
                                    {_item.buy_price === -1 ? null : (
                                      <>
                                        {_item.sell_format === "+" ? (
                                          <>
                                            <span className="ml-1 mt-1">
                                              <IoArrowUpOutline />
                                            </span>
                                          </>
                                        ) : _item.sell_format === "-" ? (
                                          <>
                                            <span>
                                              <IoArrowDownOutline />
                                            </span>
                                          </>
                                        ) : _item.sell_format ===
                                          "|" ? null : null}
                                      </>
                                    )}
                                    {_item.buy_price === -1 ? (
                                      "-"
                                    ) : (
                                      <PersianNumber
                                        value={_item.buy_price}
                                        thousandSeparator=","
                                      />
                                    )}
                                    {_item.buy_price === -1 ? null : (
                                      <p className="text-xs text-gray-400 text-center mt-2 mr-1">
                                        تومان
                                      </p>
                                    )}
                                  </span>
                                </span>
                                {/* Red Price */}
                                <span className="flex flex-col justify-center items-center text-center text-red-600 w-full mr-1">
                                  <span className="flex">
                                    {_item.sell_price === -1 ? null : (
                                      <>
                                        {_item.sell_format === "+" ? (
                                          <>
                                            <span className="ml-1 mt-1">
                                              <IoArrowUpOutline />
                                            </span>
                                          </>
                                        ) : _item.sell_format === "-" ? (
                                          <>
                                            <span>
                                              <IoArrowDownOutline />
                                            </span>
                                          </>
                                        ) : _item.sell_format ===
                                          "|" ? null : null}
                                      </>
                                    )}
                                    {_item.sell_price === -1 ? (
                                      "-"
                                    ) : (
                                      <PersianNumber
                                        value={_item.sell_price}
                                        thousandSeparator=","
                                      />
                                    )}
                                    {_item.sell_price === -1 ? null : (
                                      <p className="text-xs text-gray-400 text-center mt-2 mr-1">
                                        تومان
                                      </p>
                                    )}
                                  </span>
                                </span>
                              </div>
                            </div>
                            {/* Button */}
                            <div className="flex justify-center">
                              <button
                                onClick={() => {
                                  setStateWeight("");
                                  setStateTotal("");
                                  setDescription("");
                                  setData("");
                                  setType("");
                                  setStatePrice(_item.buy_price);
                                  setData(_item);
                                  setType("buy");
                                }}
                                className="mx-3 text-white hover:bg-green-600 
                      border-2 border-green-600 tracking-wider text-sm
                    rounded-xl px-3 py-0.5 hover:text-white transition-all ease-linear
                    duration-100 hover:transition-all hover:ease-linear hover:duration-100"
                              >
                                خرید شما
                              </button>
                              <button
                                onClick={() => {
                                  setStateWeight("");
                                  setStateTotal("");
                                  setDescription("");
                                  setData("");
                                  setType("");
                                  setStatePrice(_item.sell_price);
                                  setData(_item);
                                  setType("sell");
                                }}
                                className="mx-3 text-white hover:bg-red-600 
                      border-2 border-red-600 tracking-wider text-sm
                      rounded-xl px-3 py-0.5 hover:text-white transition-all ease-linear
                      duration-100 hover:transition-all hover:ease-linear hover:duration-100"
                              >
                                فروش شما
                              </button>
                            </div>
                          </span>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>

            {/* List View */}
            <div className="mt-10 lg:w-[88%] xl:w-[95%] 2xl:w-[100%] float-left">
              <table className="w-full">
                <thead className="text-sm font-medium text-slate-200 border-b-[1px] border-[#A79B81]">
                  <tr>
                    <th className="py-3">
                      <div>نام محصول</div>
                    </th>
                    <th className="py-3">
                      <div>خرید شما</div>
                    </th>
                    <th className="py-3">
                      <div>فروش شما</div>
                    </th>
                    <th className="py-3">
                      <div>آخرین آپدیت</div>
                    </th>
                    <th className="py-3">
                      <div>نوسانات بازار</div>
                    </th>
                  </tr>
                </thead>
                {/* Body */}
                <tbody className="text-lg divide-y divide-gray-700 w-full">
                  {getItemsList.map((_item) => (
                    <>
                      {_item.periority === false && (
                        <tr
                          key={_item.id}
                          className="hover:bg-gray-900 w-full relative h-20"
                        >
                          {/* Name */}
                          <td className="py-4">
                            <div
                              className={`${
                                _item.id === data.id ? "hidden" : "flex"
                              } items-center text-center justify-center 
                          font-medium text-slate-200
                          animate__animated animate__fadeIn animate__fast`}
                            >
                              {_item.name}
                            </div>
                          </td>
                          {/* Green Prices */}
                          <td className="py-4">
                            <div
                              onClick={() => {
                                setStateWeight("");
                                setStateTotal("");
                                setDescription("");
                                setData("");
                                setType("");
                                setStatePrice(_item.buy_price);
                                setData(_item);
                                setType("buy");
                              }}
                              className={`${
                                _item.id === data.id ? "hidden" : "flex"
                              } justify-center tracking-wide font-medium
                          cursor-pointer text-green-600
                          animate__animated animate__fadeIn animate__fast`}
                            >
                              {_item.buy_price === -1 ? null : (
                                <>
                                  {_item.sell_format === "+" ? (
                                    <>
                                      <span className="ml-1 mt-1">
                                        <IoArrowUpOutline />
                                      </span>
                                    </>
                                  ) : _item.sell_format === "-" ? (
                                    <>
                                      <span>
                                        <IoArrowDownOutline />
                                      </span>
                                    </>
                                  ) : _item.sell_format === "|" ? null : null}
                                </>
                              )}
                              {_item.buy_price === -1 ? (
                                "-"
                              ) : (
                                <PersianNumber
                                  value={_item.buy_price}
                                  thousandSeparator=","
                                />
                              )}
                              {_item.buy_price === -1 ? null : (
                                <p className="text-xs text-gray-400 text-center mt-1 mr-1">
                                  تومان
                                </p>
                              )}
                            </div>
                          </td>
                          {/* Red Prices */}
                          <td className="py-4">
                            <div
                              onClick={() => {
                                setStateWeight("");
                                setStateTotal("");
                                setDescription("");
                                setData("");
                                setType("");
                                setStatePrice(_item.sell_price);
                                setData(_item);
                                setType("sell");
                              }}
                              className={`${
                                _item.id === data.id ? "hidden" : "flex"
                              } justify-center tracking-wide font-medium
                          cursor-pointer text-red-600
                          animate__animated animate__fadeIn animate__fast`}
                            >
                              {_item.sell_price === -1 ? null : (
                                <>
                                  {_item.sell_format === "+" ? (
                                    <>
                                      <span className="ml-1 mt-1">
                                        <IoArrowUpOutline />
                                      </span>
                                    </>
                                  ) : _item.sell_format === "-" ? (
                                    <>
                                      <span>
                                        <IoArrowDownOutline />
                                      </span>
                                    </>
                                  ) : _item.sell_format === "|" ? null : null}
                                </>
                              )}
                              {_item.sell_price === -1 ? (
                                "-"
                              ) : (
                                <PersianNumber
                                  value={_item.sell_price}
                                  thousandSeparator=","
                                />
                              )}
                              {_item.sell_price === -1 ? null : (
                                <p className="text-xs text-gray-400 text-center mt-1 mr-1">
                                  تومان
                                </p>
                              )}
                            </div>
                          </td>
                          {/* Last Update */}
                          <td className="py-4">
                            <div
                              className={`${
                                _item.id === data.id ? "hidden" : "flex"
                              } justify-center text-gray-400 text-sm
                              animate__animated animate__fadeIn animate__fast`}
                            >
                              {/* {formatToPersianDate(_item.last_update_time_ago)} */}
                              {_item.last_update_time_ago}
                            </div>
                          </td>
                          {/* Discount */}
                          <td className="py-4">
                            <div
                              className={`${
                                _item.id === data.id ? "hidden" : "flex"
                              } justify-center items-center text-white font-medium text-sm
                          animate__animated animate__fadeIn animate__fast`}
                              style={{ direction: "ltr" }}
                            >
                              {_item.change === -1 ? (
                                "-"
                              ) : (
                                <span
                                  className={`${
                                    _item.change < 0
                                      ? "bg-red-500"
                                      : _item.change > 0
                                      ? "bg-green-500"
                                      : _item.change === 0
                                      ? ""
                                      : null
                                  } inline-flex items-center justify-center 
                          rounded-full px-2 py-1 text-xs font-bold 
                          leading-none text-zinc-50`}
                                >
                                  <PersianNumber
                                    value={_item.change}
                                    suffix={"%"}
                                  />
                                </span>
                              )}
                            </div>
                          </td>
                          {/* Modal */}
                          {_item.id === data.id && (
                            <div
                              className="w-full h-full absolute top-0 left-0 right-0 z-30
                      animate__animated animate__fadeIn animate__fast"
                            >
                              {/* Start Buy / Sell Modal */}
                              {!confirmModal && !resultModal && (
                                <div className="flex justify-around items-center w-full h-full">
                                  {/* Order Icon */}
                                  {type === "buy" ? (
                                    <>
                                      {_item.buy_price !== statePrice && (
                                        <span
                                          className="absolute px-2 py-1 rounded-full top-1
                                left-1 flex items-center text-slate-300"
                                        >
                                          <div className="group flex relative">
                                            <MdOutlineCurrencyExchange
                                              size={18}
                                              className="animate-spin-slow"
                                            />
                                            <span
                                              className="group-hover:opacity-100 transition-opacity 
                                 px-1 text-sm text-slate-300 rounded-md absolute left-1/2 
                                 -translate-x-1/2 translate-y-full opacity-0 m-1 mx-auto"
                                            >
                                              اردری
                                            </span>
                                          </div>
                                        </span>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {_item.sell_price !== statePrice && (
                                        <span
                                          className="absolute px-2 py-1 rounded-full top-1 
                                left-1 flex items-center text-slate-300"
                                        >
                                          <div className="group flex relative">
                                            <MdOutlineCurrencyExchange
                                              size={18}
                                              className="animate-spin-slow"
                                            />
                                            <span
                                              className="group-hover:opacity-100 transition-opacity 
                               px-1 text-sm text-slate-300 rounded-md absolute left-1/2 
                               -translate-x-1/2 translate-y-full opacity-0 m-1 mx-auto"
                                            >
                                              اردری
                                            </span>
                                          </div>
                                        </span>
                                      )}
                                    </>
                                  )}
                                  {/* Price */}
                                  <span className="relative mx-5 w-full">
                                    <input
                                      type="text"
                                      autoComplete="off"
                                      name="price"
                                      value={
                                        statePrice === -1
                                          ? ""
                                          : statePrice === ""
                                          ? statePrice + statePrice
                                          : addCommas(statePrice)
                                      }
                                      onChange={(e) => {
                                        handlePriceChange(_item, e);
                                      }}
                                      className={`${
                                        type === "buy"
                                          ? "focus:text-green-500 focus:border-green-600"
                                          : "focus:text-red-500 focus:border-red-600"
                                      } block py-3 px-2 w-full text-sm bg-transparent border-0 border-b-2
                                  border-zinc-500 appearance-none text-slate-500  
                                    focus:transition-all focus:duration-200 focus:ease-linear 
                                    transition-all duration-200 ease-linear focus:outline-0
                                    focus:outline-none focus:ring-0 peer text-left
                                   `}
                                      style={{ direction: "ltr" }}
                                      placeholder=" "
                                    />
                                    <label
                                      htmlFor="price"
                                      className={`${
                                        type === "buy"
                                          ? "peer-focus:text-green-600"
                                          : "peer-focus:text-red-600"
                                      } absolute text-xl text-gray-400
                                  duration-300 transform  -translate-y-6 scale-75
                                  right-0 top-3 -z-10 peer-focus:right-0 flex w-full justify-between
                                  peer-placeholder-shown:translate-y-0 peer-focus:-translate-y-6
                                  `}
                                    >
                                      {_item.tag === "S" ? "قیمت" : "مظنه"}
                                    </label>
                                  </span>
                                  {/* Weight */}
                                  <span className="relative mx-5 w-full">
                                    <input
                                      type="text"
                                      autoComplete="off"
                                      name="weight"
                                      value={stateWeight}
                                      onChange={(e) => {
                                        handleWeightChange(_item, e);
                                      }}
                                      className={`${
                                        type === "buy"
                                          ? "focus:text-green-500 focus:border-green-600"
                                          : "focus:text-red-500 focus:border-red-600"
                                      } block py-3 px-2 w-full text-sm bg-transparent border-0 border-b-2
            border-zinc-500 appearance-none text-slate-500  
              focus:transition-all focus:duration-200 focus:ease-linear 
              transition-all duration-200 ease-linear focus:outline-0
              focus:outline-none focus:ring-0 peer text-left
            `}
                                      placeholder=" "
                                    />
                                    <label
                                      htmlFor="weight"
                                      className={`${
                                        type === "buy"
                                          ? "peer-focus:text-green-600"
                                          : "peer-focus:text-red-600"
                                      } absolute text-xl text-gray-400
            duration-300 transform  -translate-y-6 scale-75
            right-0 top-3 -z-10 peer-focus:right-0
            peer-placeholder-shown:translate-y-0 peer-focus:-translate-y-6
            `}
                                    >
                                      {_item.tag === "S"
                                        ? "تعداد"
                                        : "مقدار (گرم)"}{" "}
                                    </label>
                                  </span>
                                  {/* Total */}
                                  <span className="relative mx-5 w-full">
                                    <input
                                      type="text"
                                      autoComplete="off"
                                      name="total"
                                      value={addCommas(stateTotal)}
                                      onChange={(e) => {
                                        handleTotalChange(_item, e);
                                      }}
                                      className={`${
                                        type === "buy"
                                          ? "focus:text-green-500 focus:border-green-600"
                                          : "focus:text-red-500 focus:border-red-600"
                                      } block py-3 px-2 w-full text-sm bg-transparent border-0 border-b-2
            border-zinc-500 appearance-none text-slate-500  
              focus:transition-all focus:duration-200 focus:ease-linear 
              transition-all duration-200 ease-linear focus:outline-0
              focus:outline-none focus:ring-0 peer text-left
            `}
                                      placeholder=" "
                                    />
                                    <label
                                      htmlFor="total"
                                      className={`${
                                        type === "buy"
                                          ? "peer-focus:text-green-600"
                                          : "peer-focus:text-red-600"
                                      } absolute text-xl text-gray-400
            duration-300 transform  -translate-y-6 scale-75
            right-0 top-3 -z-10 peer-focus:right-0
            peer-placeholder-shown:translate-y-0 peer-focus:-translate-y-6
            `}
                                    >
                                      جمع کل
                                    </label>
                                  </span>
                                  {/* Description */}
                                  <span className="relative mx-5 w-full">
                                    <input
                                      type="text"
                                      autoComplete="off"
                                      name="description"
                                      value={description}
                                      onChange={(e) =>
                                        setDescription(e.target.value)
                                      }
                                      className={`${
                                        type === "buy"
                                          ? "focus:text-green-500 focus:border-green-600"
                                          : "focus:text-red-500 focus:border-red-600"
                                      } block py-3 px-2 w-full text-sm bg-transparent border-0 border-b-2
            border-zinc-500 appearance-none text-slate-500  
              focus:transition-all focus:duration-200 focus:ease-linear 
              transition-all duration-200 ease-linear focus:outline-0
              focus:outline-none focus:ring-0 peer text-right
            `}
                                      placeholder=" "
                                    />
                                    <label
                                      htmlFor="description"
                                      className={`${
                                        type === "buy"
                                          ? "peer-focus:text-green-600"
                                          : "peer-focus:text-red-600"
                                      } absolute text-xl text-gray-400
            duration-300 transform  -translate-y-6 scale-75
            right-0 top-3 -z-10 peer-focus:right-0
            peer-placeholder-shown:translate-y-0 peer-focus:-translate-y-6
            `}
                                    >
                                      توضیحات
                                    </label>
                                  </span>
                                  {/* Button */}
                                  <span className="flex justify-center w-full">
                                    <button
                                      className="text-slate-300 text-base tracking-wider mx-4"
                                      onClick={() => {
                                        setStatePrice("");
                                        setStateWeight("");
                                        setStateTotal("");
                                        setDescription("");
                                        setData("");
                                        setType("");
                                        setConfirmModal(false);
                                        setResultModal(false);
                                      }}
                                    >
                                      لغو
                                    </button>
                                    <button
                                      onClick={() => {
                                        setConfirmModal(true);
                                        setResultModal(false);
                                      }}
                                      className={`${
                                        type === "buy"
                                          ? "bg-green-500 hover:bg-green-600"
                                          : "bg-red-500 hover:bg-red-600"
                                      } rounded-full px-3 py-0.5 mx-2
                          tracking-wider text-base text-white
                          transition-all duration-100 ease-linear
                          hover:transition-all hover:duration-100 hover:ease-linear`}
                                    >
                                      {type === "buy" ? "خرید" : "فروش"}
                                    </button>
                                  </span>
                                </div>
                              )}
                              {/* End Buy / Sell Modal */}

                              {/* Start Confirm Modal */}
                              {confirmModal && !resultModal && (
                                <>
                                  <form
                                    onSubmit={handlePostTransaction}
                                    className="absolute flex py-2 pt-3
                                    items-center justify-around w-full h-full"
                                  >
                                    <div
                                      className="flex justify-around items-center w-11/12 h-full
                            text-slate-300 text-sm
                            animate__animated animate__fadeIn animate__fast"
                                    >
                                      {/* Order Type Row */}
                                      <span className="flex flex-col items-center relative mx-5 w-full">
                                        <span className="font-small text-sm tracking-wider py-2">
                                          نوع عملیات :
                                        </span>
                                        <span className="font-medium tracking-wider text-zinc-300">
                                          {type === "buy" ? (
                                            <span className="text-green-500">
                                              خرید
                                            </span>
                                          ) : (
                                            <span className="text-red-500">
                                              فروش
                                            </span>
                                          )}
                                        </span>
                                      </span>
                                      {/* Price Row */}
                                      <span className="flex flex-col items-center  relative mx-5 w-full">
                                        <span className="font-small text-sm tracking-wider py-2">
                                          {_item.tag === "S" ? "قیمت" : "مظنه"}:
                                        </span>
                                        <span className="font-medium tracking-wider text-zinc-300">
                                          <PersianNumber
                                            value={statePrice}
                                            thousandSeparator=","
                                          />
                                        </span>
                                      </span>
                                      {/* Weight Row */}
                                      <span className="flex flex-col items-center  relative mx-5 w-full">
                                        <span className="font-bold tracking-wider py-2">
                                          {_item.tag === "S"
                                            ? "تعداد"
                                            : "مقدار (گرم)"}
                                          :
                                        </span>
                                        <span className="font-medium tracking-wider text-zinc-300">
                                          {_item.tag === "S" ? (
                                            <PersianNumber
                                              value={stateWeight}
                                              thousandSeparator=","
                                            />
                                          ) : (
                                            <PersianNumber
                                              value={stateWeight}
                                              thousandSeparator=","
                                            />
                                          )}
                                        </span>
                                      </span>
                                      {/* Total Row */}
                                      <span className="flex flex-col items-center  relative mx-5 w-full">
                                        <span className="font-bold tracking-wider py-2">
                                          جمع کل :
                                        </span>
                                        <span className="font-medium tracking-wider text-zinc-300">
                                          <PersianNumber
                                            value={stateTotal}
                                            thousandSeparator=","
                                          />
                                        </span>
                                      </span>
                                      {/* Description Row */}
                                      <span className="flex flex-col items-center  relative mx-5 w-full">
                                        <span className="font-bold tracking-wider py-2">
                                          توضیحات :
                                        </span>
                                        <p className="truncate ... w-60 text-center font-medium tracking-wider text-zinc-300">
                                          {description}{" "}
                                        </p>
                                      </span>
                                      {/* Default Row */}
                                      <span className="flex justify-center items-center w-full">
                                        <span className="font-bold tracking-wider px-2">
                                          {setProduct_name(_item.name)}
                                          {setCustomeruser(userInfo[0]?.id)}
                                          {type === "buy"
                                            ? setBos("B")
                                            : setBos("S")}
                                          {setIs_orderi(
                                            _item.buy_price !== statePrice
                                              ? true
                                              : false
                                          )}
                                        </span>
                                      </span>
                                      {/* Button */}
                                      <span className="flex justify-center w-full">
                                        <button
                                          className="text-slate-300 text-base tracking-wider mx-4"
                                          onClick={() => {
                                            setStatePrice("");
                                            setStateWeight("");
                                            setStateTotal("");
                                            setDescription("");
                                            setData("");
                                            setType("");
                                            setConfirmModal(false);
                                            setResultModal(false);
                                          }}
                                        >
                                          لغو
                                        </button>
                                        <button
                                          type="submit"
                                          className={`${
                                            type === "buy"
                                              ? "bg-green-500 hover:bg-green-600"
                                              : "bg-red-500 hover:bg-red-600"
                                          } rounded-full px-3 py-0.5 mx-2
                          tracking-wider text-base text-white
                          transition-all duration-100 ease-linear
                          hover:transition-all hover:duration-100 hover:ease-linear`}
                                        >
                                          تأیید
                                        </button>
                                      </span>
                                    </div>
                                  </form>
                                </>
                              )}
                              {/* End Confirm Modal */}

                              {/* Start Result Modal */}
                              {resultModal && (
                                <>
                                  {transactionId ? (
                                    <>
                                      <div
                                        className="flex justify-between items-center w-full h-full
                            text-slate-300 text-sm 
                              animate__animated animate__fadeIn animate__fast"
                                      >
                                        <span className="flex justify-around items-center w-full">
                                          {/* First Row */}
                                          <span
                                            className={`${
                                              type === "buy"
                                                ? "text-green-500"
                                                : "text-red-500"
                                            }
                                  font-bold text-center tracking-wide`}
                                          >
                                            {type === "buy" ? "خرید " : "فروش "}
                                            <span className="text-slate-300">
                                              شما به حالت انتظار تغییر پیدا کرد!
                                            </span>
                                          </span>
                                          {/* Second Row */}
                                          <span className="font-medium flex tracking-wider text-zinc-300">
                                            <span>شماره سفارش : </span>
                                            <span>#{transactionId}</span>
                                          </span>
                                        </span>
                                        {/* Button */}
                                        <span className="w-3/12 flex justify-center ">
                                          <button
                                            onClick={() => {
                                              setStatePrice("");
                                              setStateWeight("");
                                              setStateTotal("");
                                              setData("");
                                              setType("");
                                              setConfirmModal(false);
                                              setResultModal(false);
                                            }}
                                            className="rounded-full px-3 py-0.5 mx-2
                                  tracking-wider text-base text-white 
                                  bg-green-600 hover:bg-green-700
                                  transition-all duration-100 ease-linear
                                  hover:transition-all hover:duration-100 hover:ease-linear"
                                          >
                                            بستن
                                          </button>
                                        </span>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        className="flex justify-between items-center w-full h-full
                                    animate__animated animate__fadeIn animate__fast"
                                      >
                                        <span
                                          className="flex items-center justify-center 
                                       w-full text-slate-300 text-lg font-bold text-center"
                                        >
                                          <span>
                                            سفارش شما ثبت نگردید، لطفا دوباره
                                            تلاش کنید!
                                          </span>
                                        </span>
                                        {/* Button */}
                                        <span className="w-3/12 flex justify-center ">
                                          <button
                                            onClick={() => {
                                              setStatePrice("");
                                              setStateWeight("");
                                              setStateTotal("");
                                              setData("");
                                              setType("");
                                              setConfirmModal(false);
                                              setResultModal(false);
                                            }}
                                            className="rounded-full px-3 py-0.5 mx-2
                                  tracking-wider text-base text-white 
                                  bg-green-600 hover:bg-green-700
                                  transition-all duration-100 ease-linear
                                  hover:transition-all hover:duration-100 hover:ease-linear"
                                          >
                                            بستن
                                          </button>
                                        </span>
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                              {/* End Result Modal */}
                            </div>
                          )}
                        </tr>
                      )}
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </section>
    </>
  );
};

export default PcPriceView;
