import { createContext, useState } from "react";
import { useDispatch } from "react-redux";

import { toastErrorMessage } from "../../../utils/toastMessage/toastMessage";

import { getNewsPublicService } from "../../../services/userServices";

import {
  setLoadingFalseAction,
  setLoadingTrueAction,
} from "../../../redux/actions/loadingActions/loadingActions";

export const newsPublicContext = createContext({
  getNews: [],
  setGetNews: () => {},
  handleGetNews: () => {},
});

const NewsPublicContext = ({ children }) => {
  const dispatch = useDispatch();

  const [getNews, setGetNews] = useState([]);

  // Handle Get Items List
  const handleGetNews = async () => {
    try {
      dispatch(setLoadingTrueAction());
      const { status, data } = await getNewsPublicService();
      if (status === 200) {
        setGetNews(data);
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    } finally {
      dispatch(setLoadingFalseAction());
    }
  };

  return (
    <newsPublicContext.Provider
      value={{
        getNews,
        setGetNews,
        handleGetNews,
      }}
    >
      {children}
    </newsPublicContext.Provider>
  );
};

export default NewsPublicContext;
