import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";

import App from "./containers/App/App";
import { store } from "./redux/store/index";

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { Toaster } from 'react-hot-toast';
import "./index.css"
import "animate.css"


createRoot(document.getElementById("app")).render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
        <Toaster />
      </Router>
    </Provider>
  </React.StrictMode>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();