import { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";

const ColorChangingIcon = ({
  Icon,
  size,
  className,
  onClick,
  firstColor,
  secondColor,
}) => {
  const [color, setColor] = useState(firstColor);

  const iconSpring = useSpring({
    color: color,
    config: { duration: 1000 },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setColor((prevColor) =>
        prevColor === firstColor ? secondColor : firstColor
      );
    }, 1000);

    return () => clearInterval(interval);
  }, [firstColor, secondColor]);

  return (
    <animated.div style={{ color: iconSpring.color, cursor: "pointer" }}>
      <Icon size={size} className={className} onClick={onClick} />
    </animated.div>
  );
};

export default ColorChangingIcon;
