import { createContext, useState } from "react";
import { useDispatch } from "react-redux";

import { toastErrorMessage } from "../../../utils/toastMessage/toastMessage";

import {
  setLoadingFalseAction,
  setLoadingTrueAction,
} from "../../../redux/actions/loadingActions/loadingActions";

import {
  changeOrderTypeHistoryServices,
  getLiveTransactionAdminService,
  getTransactionsHistoryAdminServices,
} from "../../../services/userServices";
import { changeOrderTypeAdminServices } from "./../../../services/userServices";

export const transactionAdminContext = createContext({
  // Live Orders Start
  counter: "",
  setCounter: () => {},
  getLiveOrders: [],
  setGetLiveOrders: () => {},
  data: [],
  setData: () => {},
  handleGetLiveOrders: () => {},
  handleChangeOrderType: () => {},
  // Live Orders End
  // History Orders Start
  getOrdersHistory: [],
  setGetOrdersHistory: () => {},
  startDate: "",
  setStartDate: () => {},
  productType: "",
  setProductType: () => {},
  handleGetOrderHistoryAdmin: () => {},
  handleSubmit: () => {},
  handleChangeOrderTypeHistory: () => {},
  // History Orders End
});

const TransactionAdminContext = ({ children }) => {
  const dispatch = useDispatch();
  const [getLiveOrders, setGetLiveOrders] = useState([]);
  const [data, setData] = useState([]);
  const [counter, setCounter] = useState(0);

  const [getOrdersHistoryAdmin, setGetOrdersHistoryAdmin] = useState([]);
  const [startDate, setStartDate] = useState([]);
  const [productType, setProductType] = useState("");

  // Handle Get Live Orders
  const handleGetLiveOrders = async () => {
    try {
      const { status, data } = await getLiveTransactionAdminService();
      if (status === 200) {
        setGetLiveOrders(data);
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    }
  };

  // Change Order Type
  const handleChangeOrderType = async (id, tType) => {
    const objData = { id, tType };

    try {
      dispatch(setLoadingTrueAction());
      const { status } = await changeOrderTypeAdminServices(objData);
      if (status === 200) {
        handleGetLiveOrders();
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    } finally {
      dispatch(setLoadingFalseAction());
    }
  };

  // Handle Get History Orders
  const handleGetOrderHistoryAdmin = async () => {
    const objData = {
      Date: startDate,
      productType: productType,
    };
    try {
      dispatch(setLoadingTrueAction());
      const { status, data } = await getTransactionsHistoryAdminServices(
        objData
      );
      if (status === 200) {
        setGetOrdersHistoryAdmin(data);
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    } finally {
      dispatch(setLoadingFalseAction());
    }
  };

  const handleSubmitAdmin = (e) => {
    e.preventDefault();
    handleGetOrderHistoryAdmin();
  };

  // Change Order Type
  const handleChangeOrderTypeHistory = async (id, tType) => {
    const objData = { id, tType };

    try {
      dispatch(setLoadingTrueAction());
      const { status } = await changeOrderTypeHistoryServices(objData);
      if (status === 200) {
        handleGetOrderHistoryAdmin();
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
      dispatch(setLoadingFalseAction());
    }
  };

  return (
    <transactionAdminContext.Provider
      value={{
        counter,
        setCounter,
        getLiveOrders,
        setGetLiveOrders,
        data,
        setData,
        handleGetLiveOrders,
        handleChangeOrderType,
        getOrdersHistoryAdmin,
        setGetOrdersHistoryAdmin,
        startDate,
        setStartDate,
        productType,
        setProductType,
        handleGetOrderHistoryAdmin,
        handleSubmitAdmin,
        handleChangeOrderTypeHistory,
      }}
    >
      {children}
    </transactionAdminContext.Provider>
  );
};

export default TransactionAdminContext;
