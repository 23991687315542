import { useContext, useEffect } from "react";
import { isEmpty } from "lodash";

import PersianNumber from "../../../../utils/PersianNumber/PersianNumber";
import SiteTitle from "../../../../helpers/SiteTitle/SiteTitle";
import { genderType } from "../../../../utils";

import { TbMoodEmpty } from "react-icons/tb";

import { requestsAdminContext } from "../../../../context/AdminContext/RequestsAdminContext/RequestsAdminContext";

const AdminDashboardRequests = () => {
  const {
    getUsersRequest,
    handleGetUsersRequestAdmin,
    handleChangeUserRequest,
    handleDeleteUserRequest,
  } = useContext(requestsAdminContext);

  useEffect(() => {
    handleGetUsersRequestAdmin();
  }, []);

  return (
    <>
      <SiteTitle title="درخواست های ورودی" />
      <div className="mr-auto 2xl:w-[85%] xl:w-[75%] lg:w-[70%] w-full h-full mb-16">
        <div className="flex h-full justify-center items-center rounded-xl bg-[#252839]">
          <div
            className="rounded-xl flex flex-col h-full w-full
              animate__animated animate__fadeIn animate__fast"
          >
            <div className="flex w-full mt-10">
              {isEmpty(getUsersRequest) ? (
                <>
                  <span
                    className="mr-auto 2xl:w-[80%] xl:w-[70%] lg:w-[65%] w-full
                py-3 absolute top-[40%] mt-10 text-slate-200 text-xl
                flex flex-col justify-center items-center"
                  >
                    <span>
                      <TbMoodEmpty size={60} className="mb-2" />
                    </span>
                    <p>درخواستی وجود ندارد</p>
                  </span>
                </>
              ) : (
                <>
                  <table className="w-full h-full">
                    <thead className="text-sm font-medium text-slate-200 border-b-[1px] border-[#A79B81]">
                      <tr>
                        <th className="py-3">
                          <div>نام</div>
                        </th>
                        <th className="py-3">
                          <div>نام خانوادگی</div>
                        </th>
                        <th className="py-3">
                          <div>شماره موبایل</div>
                        </th>
                        <th className="py-3">
                          <div>کد ملی</div>
                        </th>
                        <th className="py-3">
                          <div>جنسیت</div>
                        </th>
                        <th className="py-3">
                          <div>عملیات</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-lg divide-y divide-gray-700 w-full h-full overflow-y-scroll">
                      {getUsersRequest.map((_item) => (
                        <>
                          <tr
                            key={_item.id}
                            className="hover:bg-gray-900 w-full h-16 relative
                        text-center lg:text-sm text-xs font-medium tracking-wide text-slate-200"
                          >
                            {/* First Name */}
                            <td>
                              <span>{_item.firstname}</span>
                            </td>
                            {/* Last Name */}
                            <td>
                              <span>
                                <p className="tracking-wider">
                                  {_item.lastname}
                                </p>
                              </span>
                            </td>
                            {/* Phone Number */}
                            <td>
                              <span>
                                <PersianNumber value={_item.phonenumber} />
                              </span>
                            </td>
                            {/* National Number */}
                            <td>
                              <span>
                                <PersianNumber value={_item.nationalid} />
                              </span>
                            </td>
                            {/* Gender */}
                            <td>
                              <span>{genderType(_item.gender)}</span>
                            </td>
                            {/* Buttons */}
                            <td>
                              <span
                                className="flex lg:flex-row-reverse flex-col 
                            justify-center items-center text-gray-950 lg:text-sm
                            text-xs font-bold lg:space-x-2 lg:space-y-0 space-y-2 space-x-0"
                              >
                                {/* Rejection Button */}
                                <span>
                                  <button
                                    onClick={() =>
                                      handleDeleteUserRequest(_item.id)
                                    }
                                    className="bg-red-600 hover:bg-red-700 text-white
                               tracking-wider lg:px-2.5 px-1.5 lg:py-1 py-0.5 rounded-full"
                                  >
                                    رد کردن
                                  </button>
                                </span>
                                {/* Accept Button */}
                                <span>
                                  <button
                                    onClick={() =>
                                      handleChangeUserRequest(_item.id, true)
                                    }
                                    className="bg-green-500 hover:bg-green-600 text-white
                              tracking-wider lg:px-2.5 px-1.5 lg:py-1 py-0.5 rounded-full"
                                  >
                                    قبول کردن
                                  </button>
                                </span>
                              </span>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboardRequests;
