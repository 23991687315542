import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";

import AuthContainer from "../AuthContainer/AuthContainer";
import AdminContainer from "../AdminContainer/AdminContainer";
import CustomerContainer from "../CustomerContainer/CustomerContainer";
import PublicContainer from "../PublicContainer/PublicContainer";
import NotFoundPage from "../../components/Page/NotFoundPage/NotFoundPage";

import PreLoader from "../../helpers/PreLoader/PreLoader";
import { decodeJWT } from "../../utils";

import {
  clearUserAction,
  setUserAction,
} from "../../redux/actions/userActions/userActions";
import { setUserInfoAction } from "../../redux/actions/userInfoActions/userInfoActions";

const App = () => {
  const dispatch = useDispatch();

  const isLoader = useSelector((state) => state.isLoader);
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      const decodedToken = decodeJWT(token);
      const dateNow = Date.now() / 1000;
      if (decodedToken?.exp < dateNow) dispatch(clearUserAction());
      else {
        dispatch(setUserAction(token));
      }
    }
  });

  useEffect(() => {
    if (token) dispatch(setUserInfoAction());
  }, [token]);

  return (
    <>
      {isLoader && <PreLoader />}
      <Routes>
        {/* Auth Container */}
        <Route path="/*" element={<AuthContainer />} />
        {/* Admin Container */}
        <Route path="/Admin/*" element={<AdminContainer />} />
        {/* Customer Container */}
        <Route path="/Customer/*" element={<CustomerContainer />} />
        {/* Public Container */}
        <Route path="/Public/*" element={<PublicContainer />} />
        {/* Not Found Component */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};

export default App;
