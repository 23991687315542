import { createContext, useState } from "react";
import { useDispatch } from "react-redux";

import { toastErrorMessage } from "../../../utils/toastMessage/toastMessage";

import {
  changeUserRequestsAdminServices,
  deleteUserRequestsAdminServices,
  getUsersRequestAdminService,
} from "../../../services/userServices";

import {
  setLoadingFalseAction,
  setLoadingTrueAction,
} from "../../../redux/actions/loadingActions/loadingActions";

export const requestsAdminContext = createContext({
  getUsersRequest: [],
  setGetUsersRequest: () => {},
  handleGetUsersRequestAdmin: () => {},
  handleChangeUserRequest: () => {},
  handleDeleteUserRequest: () => {},
});

const RequestsAdminContext = ({ children }) => {
  const dispatch = useDispatch();

  const [getUsersRequest, setGetUsersRequest] = useState([]);

  // Handle Get Users Request Admin
  const handleGetUsersRequestAdmin = async () => {
    try {
      dispatch(setLoadingTrueAction());
      const { status, data } = await getUsersRequestAdminService();
      if (status === 200) {
        setGetUsersRequest(data);
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    } finally {
      dispatch(setLoadingFalseAction());
    }
  };

  // Change User Request
  const handleChangeUserRequest = async (id, isAllowed) => {
    const objData = { id, isAllowed };

    try {
      dispatch(setLoadingTrueAction());
      const { status } = await changeUserRequestsAdminServices(objData);
      if (status === 200) {
        handleGetUsersRequestAdmin();
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
      dispatch(setLoadingFalseAction());
    }
  };

  // Change User Request
  const handleDeleteUserRequest = async (id) => {
    try {
      dispatch(setLoadingTrueAction());
      const { status } = await deleteUserRequestsAdminServices(id);
      if (status === 200) {
        handleGetUsersRequestAdmin();
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
      dispatch(setLoadingFalseAction());
    }
  };
  return (
    <requestsAdminContext.Provider
      value={{
        getUsersRequest,
        setGetUsersRequest,
        handleGetUsersRequestAdmin,
        handleChangeUserRequest,
        handleDeleteUserRequest,
      }}
    >
      {children}
    </requestsAdminContext.Provider>
  );
};

export default RequestsAdminContext;
