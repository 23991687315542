import { createContext, useState } from "react";
import { useDispatch } from "react-redux";

import { toastErrorMessage } from "../../../utils/toastMessage/toastMessage";

import {
  setLoadingFalseAction,
  setLoadingTrueAction,
} from "../../../redux/actions/loadingActions/loadingActions";

import {
  changeProductActiveServices,
  createProductsAdminService,
  editProductAdminServices,
  getProductsAdminService,
} from "../../../services/userServices";

export const productsAdminContext = createContext({
  getProducts: [],
  setGetProducts: () => {},
  addProduct: null,
  setAddProduct: () => {},
  active: null,
  setActive: () => {},
  productId: "",
  setProductId: () => {},
  handleCreateProductAdmin: () => {},
  handleGetProductsAdmin: () => {},
  handleOpenEditModal: () => {},
  handleCloseEditModal: () => {},
  handleEditProductAdmin: () => {},
  handleChangePriorityProduct: () => {},
  handleChangeActiveProduct: () => {},
});

const ProductsAdminContext = ({ children }) => {
  const dispatch = useDispatch();
  const [getProducts, setGetProducts] = useState([]);
  const [addProduct, setAddProduct] = useState(false);
  const [productId, setProductId] = useState("");

  // Handle Create Product Admin
  const handleCreateProductAdmin = async (userData) => {
    const objData = {
      ...userData,
      buy_price: Number(userData.buy_price),
      sell_price: Number(userData.sell_price),
      balance: Number(userData.balance),
      b_tel: Number(userData.b_tel),
      s_tel: Number(userData.s_tel),
    };
    try {
      dispatch(setLoadingTrueAction());
      const { status } = await createProductsAdminService(objData);
      if (status === 200) {
        setAddProduct(false);
        handleGetProductsAdmin();
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
      dispatch(setLoadingFalseAction());
    }
  };

  // Handle Get Products Admin
  const handleGetProductsAdmin = async () => {
    try {
      dispatch(setLoadingTrueAction());
      const { status, data } = await getProductsAdminService();
      if (status === 200) {
        setGetProducts(data);
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    } finally {
      dispatch(setLoadingFalseAction());
    }
  };

  // Handle Convert Persian Number To English Number
  const convertPersianToEnglish = (str) => {
    const persianDigits = "۰۱۲۳۴۵۶۷۸۹".split("");
    const englishDigits = "0123456789".split("");

    return str.replace(
      /[۰-۹]/g,
      (char) => englishDigits[persianDigits.indexOf(char)] || char
    );
  };
  // Handle Edit Product Admin
  const handleOpenEditModal = (_item, setValue) => {
    setProductId(_item.id);

    setValue("id", _item.id);
    setValue("name", _item.name);
    setValue("buy_price", convertPersianToEnglish(_item.buy_price.toString()));
    setValue(
      "sell_price",
      convertPersianToEnglish(_item.sell_price.toString())
    );
    setValue("b_tel", convertPersianToEnglish(_item.b_tel.toString()));
    setValue("s_tel", convertPersianToEnglish(_item.s_tel.toString()));
    setValue("balance", convertPersianToEnglish(_item.balance.toString()));
    setValue("tag", _item.tag);
    setValue("is_market_close", _item.is_market_close);
  };
  const handleCloseEditModal = () => {
    setProductId("");
  };

  const handleEditProductAdmin = async (userData) => {
    const objData = {
      ...userData,
      buy_price: Number(convertPersianToEnglish(userData.buy_price.toString())),
      sell_price: Number(
        convertPersianToEnglish(userData.sell_price.toString())
      ),
      balance: Number(convertPersianToEnglish(userData.balance.toString())),
      b_tel: Number(convertPersianToEnglish(userData.b_tel.toString())),
      s_tel: Number(convertPersianToEnglish(userData.s_tel.toString())),
    };

    try {
      dispatch(setLoadingTrueAction());
      const { status } = await editProductAdminServices(objData);
      if (status === 200) {
        handleCloseEditModal();
        handleGetProductsAdmin();
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    } finally {
      dispatch(setLoadingFalseAction());
    }
  };

  // Change Priority Product
  const handleChangePriorityProduct = async (id, periority) => {
    const objData = {
      id,
      periority,
    };

    try {
      dispatch(setLoadingTrueAction());
      const { status } = await changeProductActiveServices(objData);
      if (status === 200) {
        handleGetProductsAdmin();
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
      dispatch(setLoadingFalseAction());
    }
  };

  // Change Active Product
  const handleChangeActiveProduct = async (id, is_market_close) => {
    const objData = {
      id,
      is_market_close,
    };

    try {
      dispatch(setLoadingTrueAction());
      const { status } = await changeProductActiveServices(objData);
      if (status === 200) {
        handleGetProductsAdmin();
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
      dispatch(setLoadingFalseAction());
    }
  };

  return (
    <productsAdminContext.Provider
      value={{
        getProducts,
        setGetProducts,
        addProduct,
        setAddProduct,
        productId,
        setProductId,
        handleCreateProductAdmin,
        handleGetProductsAdmin,
        handleOpenEditModal,
        handleCloseEditModal,
        handleEditProductAdmin,
        handleChangePriorityProduct,
        handleChangeActiveProduct,
      }}
    >
      {children}
    </productsAdminContext.Provider>
  );
};

export default ProductsAdminContext;
