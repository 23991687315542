import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { isEmpty } from "lodash";

import CustomerLayout from "../../layouts/CustomerLayouts/CustomerLayout";
import { decodeJWT } from "../../utils";

import CustomerProfilePage from "../../components/Customer/CustomerProfilePage/CustomerProfilePage";
import CustomerChangePassword from "../../components/Customer/CustomerChangePassword/CustomerChangePassword";
import ChangePasswordCustomerContext from "../../context/CustomerContext/ChangePasswordCustomerContext/ChangePasswordCustomerContext";

const CustomerContainer = () => {
  const token = localStorage.getItem("token");

  const isLogin = !isEmpty(token);

  const roleName = decodeJWT(token)?.usertype;

  if (!isLogin || roleName !== "CUSTOMER") return <Navigate to="/" replace />;

  return (
    <>
      <CustomerLayout>
        <Routes>
          {/* Dashboard Component */}
          <Route
            path="/Change-Password"
            element={
              <ChangePasswordCustomerContext>
                <CustomerChangePassword />
              </ChangePasswordCustomerContext>
            }
          />
          {/* Profile Component */}
          <Route path="/Profile" element={<CustomerProfilePage />} />
        </Routes>
      </CustomerLayout>
    </>
  );
};

export default CustomerContainer;
