import http from "./configServices/httpService";
// import config from "./configServices/config.json";

// const apiDomain =
//     process.env.NODE_ENV === "development"
//         ? config.developmentApi
//         : config.productionApi;

// Start Auth Services
export const singUpAuthService = (data) => {
  return http.post("https://api.samarehgolestani.ir/Auth/register/", data);
};

export const otpRegisterAuthService = (data) => {
  return http.post("https://api.samarehgolestani.ir/Auth/register/token/", data);
};

export const logInAuthService = (data) => {
  return http.post("https://api.samarehgolestani.ir/Auth/login/", data);
};

export const optAuthService = (data) => {
  return http.post(`https://api.samarehgolestani.ir/Auth/login/token/`, data);
};

export const forgetPassAuthService = (data) => {
  return http.post("https://api.samarehgolestani.ir/Auth/resetpassword/token/", data);
};

export const confirmForgetPassAuthService = (data) => {
  return http.post("https://api.samarehgolestani.ir/Auth/resetpassword/", data);
};
// End Auth Services

// Start Change Password Services
export const changePasswordService = (data) => {
  return http.post("https://api.samarehgolestani.ir/Auth/changepassword/token/", data);
};

export const confirmChangePasswordService = (data) => {
  return http.post("https://api.samarehgolestani.ir/Auth/changepassword/", data);
};
// End Change Password Services

// Start User Info Services
export const getUserInfoServices = () => {
  return http.get("https://api.samarehgolestani.ir/Customer/user/");
};

// Start Price Public Services
export const postTransactionPublicService = (data) => {
  const newObj = {
    amount: data.stateTotal,
    volume: data.stateWeight,
    price: data.statePrice,
    tType: data.tType,
    description: data.description,
    bos: data.bos,
    is_orderi: data.is_orderi,
    product_name: data.product_name,
    customeruser: data.customeruser,
  };
  return http.post(
    "https://api.samarehgolestani.ir/Customer/transaction/",
    newObj
  );
};

export const getProductsListPublicService = () => {
  return http.get("https://api.samarehgolestani.ir/Public/product/");
};
// End Price Public Services

// Start Live Order Customer Services
export const getLiveOrdersCustomerService = () => {
  return http.get("https://api.samarehgolestani.ir/Customer/liveorder/");
};

export const getOrdersHistoryCustomerService = async (objData) => {
  try {
    const productType = objData.productType;
    const from = Number(objData.Date[0]);
    const to = Number(objData.Date[1]);
    let url = `https://api.samarehgolestani.ir/Customer/transactionhistory/?`;
    if (from && to) {
      url += `from=${from}&to=${to}&`;
    }
    if (productType) {
      url += `tType=${productType}`;
    }

    const response = await http.get(url);
    return response
  } catch (error) {
    // console.error("Error fetching orders history:", error);
  }
};

// Start Live Transaction Admin Service
export const getLiveTransactionAdminService = () => {
  return http.get("https://api.samarehgolestani.ir/Admin/liveorder/");
};

export const changeOrderTypeAdminServices = (data) => {
  return http.patch(
    `https://api.samarehgolestani.ir/Admin/liveorder/${data.id}`,
    data
  );
};
// End Live Transaction Admin Service

// Start Order History Admin Service
export const getTransactionsHistoryAdminServices = async (objData) => {
  try {
    const productType = objData.productType;
    const from = Number(objData.Date[0]);
    const to = Number(objData.Date[1]);
    let url = `https://api.samarehgolestani.ir/Admin/transactionhistory/?`;
    if (from && to) {
      url += `from=${from}&to=${to}&`;
    }
    if (productType) {
      url += `tType=${productType}`;
    }

    const response = await http.get(url);
    return response
  } catch (error) {
    // console.error("Error fetching orders history:", error);
  }
};

export const changeOrderTypeHistoryServices = (data) => {
  return http.patch(
    `https://api.samarehgolestani.ir/Admin/transactionhistory/${data.id}`,
    data
  );
};
// End Order History Admin Service

// Start Users Admin Service
export const createUsersAdminService = (data) => {
  return http.post("https://api.samarehgolestani.ir/Admin/user/", data);
};

export const getUsersAdminService = () => {
  return http.get("https://api.samarehgolestani.ir/Admin/user/");
};

export const editUserAdminServices = (data) => {
  return http.put(
    `https://api.samarehgolestani.ir/Admin/user/${data.id}`,
    data
  );
};

export const changeUserActiveServices = (data) => {
  return http.patch(
    `https://api.samarehgolestani.ir/Admin/user/${data.id}`,
    data
  );
};

export const deleteUsersAdminService = (id) => {
  return http.delete(`https://api.samarehgolestani.ir/Admin/user/${id}`);
};
// End Users Admin Service

// Start Products Admin Service
export const createProductsAdminService = (data) => {
  return http.post("https://api.samarehgolestani.ir/Admin/product/", data);
};

export const getProductsAdminService = () => {
  return http.get("https://api.samarehgolestani.ir/Admin/product/");
};

export const getProductAdminService = (id) => {
  return http.get(`https://api.samarehgolestani.ir/Admin/product/${id}`);
};

export const editProductAdminServices = (data) => {
  return http.put(
    `https://api.samarehgolestani.ir/Admin/product/${data.id}`,
    data
  );
};

export const changeProductActiveServices = (data) => {
  return http.patch(
    `https://api.samarehgolestani.ir/Admin/product/${data.id}`,
    data
  );
};
// End Products Admin Service

// Start Users Requests Admin Service
export const getUsersRequestAdminService = () => {
  return http.get("https://api.samarehgolestani.ir/Admin/user/acception/");
};

export const changeUserRequestsAdminServices = (data) => {
  return http.patch(
    `https://api.samarehgolestani.ir/Admin/user/${data.id}`,
    data
  );
};

export const deleteUserRequestsAdminServices = (id) => {
  return http.delete(`https://api.samarehgolestani.ir/Admin/user/${id}`);
};
// End Users Requests  Admin Service

// Start News Public Services
export const getNewsPublicService = () => {
  return http.get("https://api.samarehgolestani.ir/Public/news/");
};
// End News Public Services

// Start News Admin Services
export const createNewsAdminService = (data) => {
  return http.post("https://api.samarehgolestani.ir/Admin/news/", data);
};

export const getNewsAdminService = () => {
  return http.get("https://api.samarehgolestani.ir/Admin/news/");
};

export const editNewsAdminServices = (data) => {
  return http.put(
    `https://api.samarehgolestani.ir/Admin/news/${data.id}`,
    data
  );
};

export const deleteNewsAdminServices = (id) => {
  return http.delete(`https://api.samarehgolestani.ir/Admin/news/${id}`);
};
// End News Admin Services

export const getUserToleranceAdminService = async (objData) => {
  try {
    const productName = objData.productName
    const userID = objData.userID;
    let url = `https://api.samarehgolestani.ir/Admin/trader/?`;
    if (productName && userID) {
      url += `productID=${productName}&userID=${userID}&`;
    }

    const response = await http.get(url);
    return response
  } catch (error) {
    // console.error("Error fetching orders history:", error);
  }
};

export const editUserToleranceAdminServices = (data, id) => {
  return http.put(
    `https://api.samarehgolestani.ir/Admin/trader/${id}`,
    data
  );
};
// End User Tolerance Services

// End Store Status Admin Services
export const getGetStoreStatusAdminService = () => {
  return http.get("https://api.samarehgolestani.ir/Admin/storestatus/");
};

export const changeStoreStatusAdminServices = (data) => {
  return http.patch(
    `https://api.samarehgolestani.ir/Admin/storestatus/${data.id}`,
    data
  );
};
// End Store Status Admin Services
