import AdminMobileMenu from "./AdminMobileMenu/AdminMobileMenu";
import AdminPcMenu from "./AdminPcMenu/AdminPcMenu";

const AdminLayout = ({ children }) => {
  return (
    <>
      <AdminPcMenu />
      <main>{children}</main>
      <AdminMobileMenu />
    </>
  );
};

export default AdminLayout;
