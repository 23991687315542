import { createContext, useState } from "react";
import { useDispatch } from "react-redux";

import { toastErrorMessage } from "../../../utils/toastMessage/toastMessage";

import {
  setLoadingFalseAction,
  setLoadingTrueAction,
} from "../../../redux/actions/loadingActions/loadingActions";

import {
  getLiveOrdersCustomerService,
  getOrdersHistoryCustomerService,
} from "../../../services/userServices";

export const transactionsCustomerContext = createContext({
  counter: "",
  setCounter: () => {},
  startDate: "",
  setStartDate: () => {},
  productType: "",
  setProductType: () => {},
  // Orders History Start
  getOrdersHistoryCustomer: [],
  setGetOrdersHistoryCustomer: () => {},
  handleGetOrderHistory: () => {},
  handleSubmitCustomer: () => {},
  // Orders History End
  // Live Orders Start
  data: [],
  setData: () => {},
  getLiveOrders: [],
  setGetLiveOrders: () => {},
  handleGetLiveOrders: () => {},
  // Live Orders End
});

const TransactionsCustomerContext = ({ children }) => {
  const dispatch = useDispatch();

  const [getLiveOrders, setGetLiveOrders] = useState([]);
  const [data, setData] = useState([]);
  const [getOrdersHistoryCustomer, setGetOrdersHistoryCustomer] = useState([]);
  const [counter, setCounter] = useState(0);

  const [startDate, setStartDate] = useState("");
  const [productType, setProductType] = useState("");

  // Handle Get Live Orders
  const handleGetLiveOrders = async () => {
    try {
      const { status, data } = await getLiveOrdersCustomerService();
      if (status === 200) {
        setGetLiveOrders(data);
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    }
  };

  // Handle Get History Orders
  const handleGetOrderHistory = async () => {
    const objData = {
      Date: startDate,
      productType: productType,
    };
    try {
      dispatch(setLoadingTrueAction());
      const { status, data } = await getOrdersHistoryCustomerService(objData);
      if (status === 200) {
        setGetOrdersHistoryCustomer(data);
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    } finally {
      dispatch(setLoadingFalseAction());
    }
  };

  // Handle Get Response For Filter
  const handleSubmitCustomer = (e) => {
    e.preventDefault();
    handleGetOrderHistory();
  };

  return (
    <transactionsCustomerContext.Provider
      value={{
        counter,
        setCounter,
        startDate,
        setStartDate,
        productType,
        setProductType,
        getLiveOrders,
        setGetLiveOrders,
        data,
        setData,
        getOrdersHistoryCustomer,
        setGetOrdersHistoryCustomer,
        handleGetLiveOrders,
        handleGetOrderHistory,
        handleSubmitCustomer,
      }}
    >
      {children}
    </transactionsCustomerContext.Provider>
  );
};

export default TransactionsCustomerContext;
