import { useState } from "react";
import { NavLink, Navigate } from "react-router-dom";
import { isEmpty } from "lodash";

import {
  MdOutlineHistoryEdu,
  MdOutlineHistory,
  MdOutlineLogout,
  MdSpaceDashboard,
  MdNotifications,
} from "react-icons/md";
import { HiOutlineHome } from "react-icons/hi";

import { logo } from "../../../assets";
import { decodeJWT } from "../../../utils";

import NewsModal from "../../../helpers/NewsModal/NewsModal";
import ColorChangingIcon from "../../../helpers/ColorChangingIcon/ColorChangingIcon";
import NewsPublicContext from "../../../context/PublicContext/NewsPublicContext/NewsPublicContext";

const PublicPcMenu = () => {
  const [openModal, setOpenModal] = useState(false);

  const token = localStorage.getItem("token");
  const isLogin = !isEmpty(token);
  const roleName = decodeJWT(token)?.usertype;

  if (!isLogin) return <Navigate to="/" replace />;

  return (
    <>
      <div
        className="fixed z-10 top-0 pb-3 px-6 w-full lg:flex hidden flex-col 
      justify-between h-screen border-l border-gray-600 
      bg-[#1A1D27] transition duration-300 
      md:w-4/12 lg:ml-0 lg:w-[25%] xl:w-[20%] 2xl:w-[15%]"
      >
        <div>
          {/* News */}
          <div>
            <span className="absolute top-2 left-2">
              <ColorChangingIcon
                Icon={MdNotifications}
                size={30}
                className="animate__animated animate__swing animate__slow animate__infinite"
                firstColor="#facc15"
                secondColor="#fff"
                onClick={() => setOpenModal(!openModal)}
              />
            </span>
            <span>
              <span className="flex justify-center items-center w-full h-full">
                {openModal && (
                  <NewsPublicContext>
                    <NewsModal />
                  </NewsPublicContext>
                )}
              </span>
            </span>
          </div>
          {/* Title Text */}
          <span className="text-slate-200 absolute right-[42%] top-3">
            <p>هو الرزاق</p>
          </span>
          {/* Logo */}
          <div className="flex justify-center py-8 mt-2">
            <img
              src={logo}
              width={200}
              className="rounded-full bg-cover"
              alt="Website Logo"
            />
          </div>
          {/* Buttons */}
          <ul className="space-y-3 tracking-wide mt-10 text-white font-medium">
            {/* 1 */}
            <li>
              <NavLink
                to="/Public/Home"
                className={({ isActive }) =>
                  isActive
                    ? "relative px-4 py-3 flex items-center space-x-4 bg-sky-900 rounded-xl cursor-pointer"
                    : "relative px-4 py-3 flex items-center space-x-4 rounded-xl text-white cursor-pointer"
                }
              >
                <span className="flex">
                  <HiOutlineHome size={25} className="ml-2" />
                  <span className="-mr-1 mt-1">صفحه اصلی</span>
                </span>
              </NavLink>
            </li>
            {/* 2 */}
            <li>
              <NavLink
                to="/Public/Live-Transactions"
                className={({ isActive }) =>
                  isActive
                    ? "relative px-4 py-3 flex items-center space-x-4 bg-sky-900 rounded-xl cursor-pointer"
                    : "relative px-4 py-3 flex items-center space-x-4 rounded-xl cursor-pointer"
                }
              >
                <span className="flex">
                  <MdOutlineHistoryEdu size={25} className="ml-2" />
                  <span className="-mr-1 mt-1">معاملات جاری</span>
                </span>
              </NavLink>
            </li>
            {/* 3 */}
            <li>
              <NavLink
                to="/Public/Last-Transactions"
                className={({ isActive }) =>
                  isActive
                    ? "relative px-4 py-3 flex items-center space-x-4 bg-sky-900 rounded-xl cursor-pointer"
                    : "relative px-4 py-3 flex items-center space-x-4 rounded-xl cursor-pointer"
                }
              >
                <span className="flex">
                  <MdOutlineHistory size={25} className="ml-2" />
                  <span className="-mr-1 mt-1">آخرین معاملات</span>
                </span>
              </NavLink>
            </li>
            {/* 4 */}
            <li>
              <NavLink
                to={
                  roleName === "ADMIN"
                    ? "/Admin/Dashboard/Profile"
                    : "/Customer/Profile"
                }
                className="relative px-4 py-3 flex items-center space-x-4 rounded-xl cursor-pointer"
              >
                <span className="flex">
                  <MdSpaceDashboard size={25} className="ml-2" />
                  <span className="-mr-1 mt-1">داشبورد</span>
                </span>
              </NavLink>
            </li>
          </ul>
        </div>
        {/* Exit Button */}
        <div className="px-6 -mx-6 pt-4 flex items-center border-t border-gray-600">
          <NavLink to="/Log-Out">
            <button className="px-4 py-3 flex items-center space-x-4 rounded-md text-slate-200 hover:text-gray-400">
              <MdOutlineLogout size={25} className="ml-2" />
              <span>خروج</span>
            </button>
          </NavLink>
        </div>
      </div>
      {/* End Menu */}
    </>
  );
};

export default PublicPcMenu;
