import { SpinnerDotted } from "spinners-react";

const PreLoader = () => {
  return (
    <>
      <span
        className="absolute top-0 bottom-0 left-0 right-0
         z-50 flex justify-center items-center"
      >
        <SpinnerDotted
          size={90}
          thickness={180}
          speed={116}
          color="rgba(57, 96, 172, 1)"
        />
      </span>
    </>
  );
};

export default PreLoader;
